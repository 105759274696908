define("ember-bootstrap-controls/components/bootstrap-textarea", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-textarea", "ember-bootstrap-controls/mixins/components/inputable"], function (_exports, _bootstrapTextarea, _inputable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inputable.default, {
    layout: _bootstrapTextarea.default,
    classNames: ['form-group', 'bootstrap-textarea-component'],
    classNameBindings: ['hasSuccess:has-success', 'hasWarning:has-warning', 'showError:has-error'],
    placeholder: null,
    value: null,
    type: null,
    tabindex: 0,
    required: false
  });

  _exports.default = _default;
});