define('ember-authenticate-me/adapters/authenticated', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.default.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),

    /* Set application authentication header */
    headers: Ember.computed('session.content.token', function () {
      var token = this.get('session.content.token');

      if (token) {
        return {
          Authorization: 'Token token=' + token
        };
      } else {
        return {};
      }
    }),

    handleResponse: function handleResponse(status /*, headers, payload*/) {
      var _this = this,
          _arguments = arguments;

      if (status === 401) {
        return this.get('session').close().finally(function () {
          var router = Ember.getOwner(_this).lookup('router:main');

          router.transitionTo('login');
          _this.store.unloadAll();
          return _this._super.apply(_this, _arguments);
        });
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});