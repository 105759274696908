define('ember-authenticate-me/components/signup-form', ['exports', 'ember-authenticate-me/templates/components/signup-form'], function (exports, _signupForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _signupForm.default,
    tagName: '',

    _isSaving: false,

    actions: {
      save: function save(user) {
        var _this = this;

        this.set('_isSaving', true);

        this.get('signup')(user).finally(function () {
          _this.set('_isSaving', false);
        });
      }
    }
  });
});