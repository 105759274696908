define('ember-bootstrap-controls/components/bootstrap-radio-group', ['exports', 'ember-bootstrap-controls/templates/components/bootstrap-radio-group', 'ember-bootstrap-controls/mixins/components/inputable'], function (exports, _bootstrapRadioGroup, _inputable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputable.default, {
    tagName: '',
    classNames: '',
    layout: _bootstrapRadioGroup.default,

    selected: null,
    placeholder: null,
    options: null,
    onChange: null,
    label: null,

    required: false
  });
});