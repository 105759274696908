define("ember-bootstrap-controls/components/bootstrap-inputs/-checkbox", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-inputs/-checkbox", "ember-prop-types", "ember-bootstrap-controls/utils/prop-definition-tools"], function (_exports, _checkbox, _emberPropTypes, _propDefinitionTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.propDefinitions = void 0;
  var propDefinitions = {
    disabled: {
      description: 'Indicates whether the control is disabled',
      type: _emberPropTypes.PropTypes.bool
    },
    label: {
      description: 'The label for the input.',
      type: _emberPropTypes.PropTypes.string.isRequired
    },
    required: {
      default: false,
      description: 'Indicates that the user must fill in a value before submitting a form.',
      type: _emberPropTypes.PropTypes.bool
    },
    srOnly: {
      default: false,
      description: 'Indicated that the label should be hidden to all devices except screen readers',
      type: _emberPropTypes.PropTypes.bool
    },
    tabindex: {
      description: 'The position of the element in the tabbing navigation order for the current document.',
      type: _emberPropTypes.PropTypes.number
    },
    value: {
      description: 'A boolean that is the value for the control.',
      type: _emberPropTypes.PropTypes.bool.isRequired
    },
    onChange: {
      description: 'A function that is called when the checkbox is changed.',
      type: _emberPropTypes.PropTypes.func
    }
  };
  _exports.propDefinitions = propDefinitions;

  var _default = Ember.Component.extend({
    classNames: ['form-check'],
    layout: _checkbox.default,
    propTypes: (0, _propDefinitionTools.BuilderForPropTypes)(propDefinitions),
    _disabled: Ember.computed.or('formDisabled', 'disabled'),
    getDefaultProps: function getDefaultProps() {
      return (0, _propDefinitionTools.BuilderForPropDefaults)(propDefinitions);
    },
    inputId: Ember.computed(function () {
      return "bootstrap-control-input-".concat(Ember.guidFor(this));
    })
  });

  _exports.default = _default;
});