define('ember-authenticate-me/routes/logout', ['exports', 'ember-authenticate-me/mixins/routes/authenticated'], function (exports, _authenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticated.default, {
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('session').close().then(function () {
        var sessionLifecycle = _this.get('sessionLifecycle');

        sessionLifecycle.userLoggedOut();
        _this.transitionTo('login');
      }).catch(function (error) {
        Ember.Logger.error("Error logging out: ", error);
        _this.transitionTo('login');
      }).finally(function () {
        _this.store.unloadAll();
      });
    }
  });
});