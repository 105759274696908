define("ember-bootstrap-controls/components/freestyle/bootstrap-input", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-input"], function (_exports, _bootstrapInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bootstrapInput.default,
    value: undefined,
    readonly: false,
    disabled: false,
    actions: {
      keyPress: function keyPress() {},
      keyUp: function keyUp() {},
      keyDown: function keyDown() {}
    }
  });

  _exports.default = _default;
});