define("ember-bootstrap-controls/components/bootstrap/-form-controls/edit-button", ["exports", "ember-bootstrap-controls/components/bootstrap-button", "ember-bootstrap-controls/templates/components/bootstrap/-form-controls/edit-button"], function (_exports, _bootstrapButton, _editButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bootstrapButton.default.extend({
    layout: _editButton.default,
    classNames: ['btn-primary']
  });

  _exports.default = _default;
});