define('ember-bootstrap-controls/components/bootstrap-checkbox', ['exports', 'ember-bootstrap-controls/templates/components/bootstrap-checkbox'], function (exports, _bootstrapCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bootstrapCheckbox.default,

    classNames: ['checkbox', 'bootstrap-checked-component'],
    attributeBindings: ['disabled'],

    label: null,
    name: null,
    value: null,
    inputId: null,
    disabled: false,
    required: false,
    autofocus: false,
    tabindex: 0,

    isChecked: false,
    srOnly: null,

    // Actions
    onclick: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('errors')) {
        Ember.deprecate("bootstrap-checkbox errors will be removed in the next minor version of ember-bootstrap-controls. Checkboxes should be hidden if they accept invalid input.", false, // always raise depecation
        { id: 'ember-bootstrap-controls' });
      }
    },


    actions: {
      change: function change(checked) {
        var onclickFunc = this.get('onclick');

        if (onclickFunc) {
          this.set('isChecked', checked);
          return onclickFunc(checked, !checked);
        }
      }
    }
  });
});