define("ember-bootstrap-controls/components/freestyle/bootstrap/tables/body/row/actions/-show", ["exports", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/templates/components/freestyle/bootstrap/tables/body/row/actions/-show", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/body/bootstrap/tables/body/row/actions/-show"], function (_exports, _show, _propDefinitionTools, _show2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _show.default,
    propDefinitions: _show2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_show2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});