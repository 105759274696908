define("ember-bootstrap-controls/components/bootstrap/tables/body/row/actions/-archive", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/tables/body/row/actions/-archive"], function (_exports, _archive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _archive.default,
    tagName: ''
  });

  _exports.default = _default;
});