define('ember-bootstrap-controls/components/bootstrap-checkbox-group', ['exports', 'ember-bootstrap-controls/templates/components/bootstrap-checkbox-group', 'ember-bootstrap-controls/mixins/components/inputable'], function (exports, _bootstrapCheckboxGroup, _inputable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputable.default, {
    tagName: '',
    classNames: '',
    layout: _bootstrapCheckboxGroup.default,

    selected: null,
    placeholder: null,
    options: null,
    onChange: null,
    label: null,

    required: false,

    actions: {
      onChange: function onChange(option, isChecked) {
        var selected = Ember.A(this.get('selected'));

        if (isChecked) {
          this.get('onChange')(selected.concat(option), selected);
        } else {
          var index = selected.indexOf(option);
          selected.splice(index, 1);
          this.get('onChange')(selected, this.get('selected'));
        }
      }
    }
  });
});