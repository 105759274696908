define("ember-bootstrap-controls/components/freestyle/bootstrap-pagination-nav", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-pagination-nav"], function (_exports, _bootstrapPaginationNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bootstrapPaginationNav.default,
    numberOfElements: 100,
    pageNumber: 1,
    pageSize: 5,
    totalPages: Ember.computed('pageSize', 'numberOfElements', function () {
      if (this.get('pageSize') && this.get('pageSize') > 0) {
        return Math.ceil(this.get('numberOfElements') / this.get('pageSize'));
      } else {
        return this.get('numberOfElements');
      }
    }),
    elements: Ember.computed('pageSize', 'pageNumber', function () {
      var counter = [];
      var start = (this.get('pageNumber') - 1) * this.get('pageSize') + 1;
      var end = start + parseInt(this.get('pageSize'));

      for (var i = start; i < end; i++) {
        counter.push(i);
      }

      return counter;
    })
  });

  _exports.default = _default;
});