define("ember-bootstrap-controls/components/bootstrap/-label", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/-label", "ember-prop-types"], function (_exports, _label, _emberPropTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _label.default,
    tagName: 'label',
    classNameBindings: ['srOnly:sr-only'],
    attributeBindings: ['for'],
    propTypes: {
      // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      for: _emberPropTypes.PropTypes.string.isRequired,
      label: _emberPropTypes.PropTypes.string.isRequired,
      srOnly: _emberPropTypes.PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
      return {
        srOnly: false
      };
    }
  });

  _exports.default = _default;
});