define("ember-bootstrap-controls/mixins/routes/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      this.queryParams = Object.assign({
        pageNumber: {
          refreshModel: true
        },
        pageSize: {
          refreshModel: true
        }
      }, this.get('queryParams'));
      return this._super.apply(this, arguments);
    },
    paginationParams: function paginationParams(params) {
      return {
        offset: params.pageNumber ? params.pageNumber : 1,
        size: params.pageSize ? params.pageSize : 25
      };
    },
    afterModel: function afterModel(results) {
      this.setProperties({
        pageNumber: results.get('meta.page.offset'),
        pageSize: results.get('meta.page.size'),
        totalPages: results.get('meta.page.total_pages'),
        totalRecords: results.get('meta.page.total_records'),
        currentIterationCount: results.get('meta.page.current_iteration_count')
      });
      return this._super.apply(this, arguments);
    },
    setupController: function setupController(controller) {
      controller.setProperties({
        pageNumber: parseInt(this.get('pageNumber')),
        pageSize: parseInt(this.get('pageSize')),
        totalPages: parseInt(this.get('totalPages')),
        totalRecords: parseInt(this.get('totalRecords')),
        currentIterationCount: parseInt(this.get('currentIterationCount'))
      });
      return this._super.apply(this, arguments);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('pageNumber', 1);
      }

      return this._super.apply(this, arguments);
    },
    actions: {
      onPage: function onPage(pageNumber, pageSize) {
        var controller = this.get('controller');

        if (Ember.isPresent(controller)) {
          controller.setProperties({
            pageNumber: pageNumber,
            pageSize: pageSize
          });
        }
      }
    }
  });

  _exports.default = _default;
});