define("ember-bootstrap-controls/components/freestyle-prop-types/-array-of", ["exports", "ember-bootstrap-controls/templates/components/freestyle-prop-types/-array-of"], function (_exports, _arrayOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _arrayOf.default
  });

  _exports.default = _default;
});