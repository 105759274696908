define("ember-bootstrap-controls/components/bootstrap-currency-input", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-currency-input", "ember-bootstrap-controls/mixins/components/inputable"], function (_exports, _bootstrapCurrencyInput, _inputable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inputable.default, {
    tagName: '',
    classNames: '',
    layout: _bootstrapCurrencyInput.default,
    placeholder: null,
    value: null,
    readonly: null,
    type: null,
    srOnly: false,
    required: true,
    prefix: '$',
    allowDecimal: true,
    decimalLimit: 2,
    hasValue: Ember.computed('value', function () {
      var value = this.get('value');
      return value ? true : false;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    actions: {
      update: function update(unmasked, masked) {
        Ember.set(this, 'value', unmasked);
        Ember.set(this, 'masked', masked);

        if (this.onUpdate) {
          this.onUpdate(unmasked, masked);
        }
      }
    }
  });

  _exports.default = _default;
});