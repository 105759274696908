define("ember-bootstrap-controls/components/modals/form/-footer", ["exports", "ember-bootstrap-controls/templates/components/modals/form/-footer"], function (_exports, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _footer.default,
    tagName: ''
  });

  _exports.default = _default;
});