define('ember-authenticate-me/controllers/edit-account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    transitionRoute: 'index',

    transitionOut: function transitionOut() {
      var transitionRoute = this.get('transitionRoute');

      this.transitionToRoute(transitionRoute);
    },

    actions: {
      save: function save(user) {
        var _this = this;

        user.get('errors').clear();
        return user.save().then(function () {
          _this.transitionOut();
        });
      },

      cancel: function cancel(user) {
        if (window.confirm("All unsved changes will be lost. Are you sure?")) {
          user.rollbackAttributes();
          this.transitionOut();
        }
      }
    }
  });
});