define("ember-bootstrap-controls/components/power-select/lazy-options", ["exports", "ember-bootstrap-controls/templates/components/power-select/lazy-options", "ember-power-select/components/power-select/options"], function (_exports, _lazyOptions, _options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _options.default.extend({
    layout: _lazyOptions.default,
    canLoadMore: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    loadMore: function loadMore() {
      var select = this.get('select');

      if (select) {
        var searchAction = Ember.get(select, 'actions.search');
        var searchText = Ember.get(select, 'searchText');

        if (searchAction && searchText) {
          return searchAction(searchText);
        }
      }
    },
    attachCheckVisibilty: function attachCheckVisibilty() {
      var _this = this;

      var checkVisibility = function checkVisibility() {
        if ($(_this.element).find('.ember-power-select-option--load-more').checkInView(true)) {
          // eslint-disable-line ember/no-global-jquery
          _this.loadMore();
        }
      };

      $(this.element).on('scroll', checkVisibility); // eslint-disable-line ember/no-global-jquery
    },
    removeCheckVisibility: function removeCheckVisibility() {
      $(this.element).off('scroll'); // eslint-disable-line ember/no-global-jquery
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.attachCheckVisibilty();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.removeCheckVisibility();
    },
    actions: {
      loadMore: function loadMore() {
        this.loadMore();
      }
    }
  });

  _exports.default = _default;
});