define('ember-freestyle/components/freestyle-showdown-content', ['exports', 'ember-freestyle/templates/components/freestyle-showdown-content'], function (exports, _freestyleShowdownContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Component.extend({
    layout: _freestyleShowdownContent.default,

    emberFreestyle: inject.service(),

    didInsertElement: function didInsertElement() {
      var pre = this.$('pre');
      if (pre[0]) {
        this.get('emberFreestyle').highlight(pre[0]);
      }
    }
  });
});