define("ember-bootstrap-controls/components/bootstrap-inputs/-time", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-inputs/-time", "ember-prop-types", "ember-bootstrap-controls/utils/prop-definition-tools"], function (_exports, _time, _emberPropTypes, _propDefinitionTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.propDefinitions = void 0;
  var autoCompleteTypes = ['off', 'on'];
  var propDefinitions = {
    autocomplete: {
      default: 'on',
      description: 'Indicates whether the value can be automatically completed by the browser.',
      type: _emberPropTypes.PropTypes.oneOf(autoCompleteTypes)
    },
    disabled: {
      description: 'Indicates whether the control is disabled',
      type: _emberPropTypes.PropTypes.bool
    },
    errors: {
      description: 'An array of EmberData errors to display.',
      type: _emberPropTypes.PropTypes.arrayOf(_emberPropTypes.PropTypes.string)
    },
    help: {
      description: 'Additonal text to provide additional context to the user that is displayed below the input.',
      type: _emberPropTypes.PropTypes.string
    },
    label: {
      description: 'The label for the input.',
      type: _emberPropTypes.PropTypes.string.isRequired
    },
    max: {
      description: 'The maximum value for the input. Must not be less than its minimum (min attribute) value.',
      type: _emberPropTypes.PropTypes.number
    },
    min: {
      description: 'The minimum value for this input, which must not be greater than its maximum (max attribute) value.',
      type: _emberPropTypes.PropTypes.number
    },
    placeholder: {
      description: 'A hint to the user of what can be entered in the control. This is displayed in the empty input.',
      type: _emberPropTypes.PropTypes.string
    },
    readonly: {
      default: false,
      description: 'Indicates that the user cannot modify the value of the control.',
      type: _emberPropTypes.PropTypes.bool
    },
    required: {
      default: false,
      description: 'Indicates that the user must fill in a value before submitting a form.',
      type: _emberPropTypes.PropTypes.bool
    },
    srOnly: {
      default: false,
      description: 'Indicated that the label should be hidden to all devices except screen readers',
      type: _emberPropTypes.PropTypes.bool
    },
    step: {
      description: 'Works with the min and max attributes to limit the increments at which a value can be set. It can be the string any or a positive floating point number. If this attribute is not set to any, the control accepts only values at multiples of the step value greater than the minimum.',
      type: _emberPropTypes.PropTypes.number
    },
    tabindex: {
      description: 'The position of the element in the tabbing navigation order for the current document.',
      type: _emberPropTypes.PropTypes.number
    },
    value: {
      description: 'A string that is the value for the control.',
      type: _emberPropTypes.PropTypes.string.isRequired
    }
  };
  _exports.propDefinitions = propDefinitions;

  var _default = Ember.Component.extend({
    layout: _time.default,
    propTypes: (0, _propDefinitionTools.BuilderForPropTypes)(propDefinitions),
    _disabled: Ember.computed.or('formDisabled', 'disabled'),
    getDefaultProps: function getDefaultProps() {
      return (0, _propDefinitionTools.BuilderForPropDefaults)(propDefinitions);
    }
  });

  _exports.default = _default;
});