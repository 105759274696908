define("ember-bootstrap-controls/components/bootstrap-inputs/-radio", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-inputs/-radio"], function (_exports, _radio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-check'],
    classNameBindings: ['inline:form-check-inline'],
    inline: false,
    layout: _radio.default,
    inputId: Ember.computed(function () {
      return "bootstrap-control-input-".concat(Ember.guidFor(this));
    })
  });

  _exports.default = _default;
});