define('ember-authenticate-me/torii-adapters/base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var assert = Ember.assert,
      inject = Ember.inject;
  exports.default = Ember.Object.extend({
    store: inject.service('store'),
    session: inject.service('session'),

    /*
     * Injected into the app through the application torii-adapter.
     * See `app/torii-adapters/application.js` for details
     */
    sessionUri: null,
    sessionKey: null,

    _ajaxOptions: function _ajaxOptions(authorizaton) {
      return {
        url: this.get('sessionUri'),
        data: JSON.stringify(authorizaton),
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json'
      };
    },
    _ajaxError: function _ajaxError(jqXHR /*, responseText*/) {
      if (jqXHR && (typeof jqXHR === 'undefined' ? 'undefined' : _typeof(jqXHR)) === 'object') {
        jqXHR.then = null;
      }

      return jqXHR;
    },
    _storeCurrentUser: function _storeCurrentUser(userPayload) {
      var store = this.get('store');

      var user = store.push(store.normalize('token-authenticate-me/user', userPayload));

      return Ember.RSVP.resolve(user);
    },
    _returnSessionPayload: function _returnSessionPayload(sessionPayload) {
      return this._storeCurrentUser(sessionPayload.user).then(function (currentUser) {
        return {
          token: sessionPayload.key,
          expiration: sessionPayload.expiration,
          currentUser: currentUser
        };
      });
    },
    open: function open(authorizaton) {
      var _this = this;

      var hash = this._ajaxOptions(authorizaton);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        hash.success = function (json /*, textStatus, jqXHR*/) {
          Ember.run(null, resolve, json);
        };

        hash.error = function (jqXHR /*, textStatus, errorThrown*/) {
          Ember.run(null, reject, _this._ajaxError(jqXHR, jqXHR.responseText));
        };

        Ember.$.ajax(hash);
      }).then(function (params) {
        var session = _this.get('session');
        var sessionKey = _this.get('sessionKey');
        var sessionPayload = params[sessionKey];

        assert('Unable to find session key ' + sessionKey + ' in payload ' + params, sessionPayload);

        session.set('content', { token: sessionPayload.key });

        if (window.localStorage) {
          window.localStorage.setItem('eam-token', sessionPayload.key);
        } else {
          _this.set('token', sessionPayload.key);
        }

        return _this._storeCurrentUser(sessionPayload.user).then(function (currentUser) {
          return {
            token: sessionPayload.key,
            expiration: sessionPayload.expiration,
            currentUser: currentUser
          };
        });
      });
    },


    /**
    * This method destroys/unauthenticates the existing session with the server.
    **/
    close: function close() {
      var _this2 = this;

      var token = null;

      if (window.localStorage) {
        token = window.localStorage.getItem('eam-token');
      } else {
        token = this.get('token');
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: _this2.get('sessionUri'),
          type: 'DELETE',
          dataType: 'json',
          contentType: 'application/json',
          headers: { Authorization: 'Token token=' + token },
          success: Ember.run.bind(null, resolve),
          error: Ember.run.bind(null, reject)
        }).then(function (data) {
          return data;
        });
      });
    },


    /**
    * This method is for checking to see if there is a session saved somewhere in memory
    * and then verifying that it is valid.
    **/
    fetch: function fetch() {
      var _this3 = this;

      var token = null;

      if (window.localStorage) {
        token = window.localStorage.getItem('eam-token');
      }

      if (token) {
        var hash = {
          url: this.get('sessionUri'),
          type: 'GET',
          dataType: 'json',
          contentType: 'application/json',
          headers: { Authorization: 'Token token=' + token }
        };

        return new Ember.RSVP.Promise(function (resolve, reject) {
          hash.success = function (json /*, textStatus, jqXHR*/) {
            Ember.run(null, resolve, json);
          };

          hash.error = function (jqXHR /*, textStatus, errorThrown*/) {
            Ember.run(null, reject, _this3._ajaxError(jqXHR, jqXHR.responseText));
          };

          Ember.$.ajax(hash);
        }).then(function (params) {
          var sessionKey = _this3.get('sessionKey');
          var session = _this3.get('session');
          var sessionPayload = params[sessionKey];

          assert('Unable to find session key ' + sessionKey + ' in payload ' + params, sessionPayload);

          session.set('content', { token: sessionPayload.key });

          return _this3._returnSessionPayload(sessionPayload);
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          reject();
        });
      }
    }
  });
});