define("ember-bootstrap-controls/components/freestyle/bootstrap/radio-group", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap/radio-group", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap/radio-group"], function (_exports, _radioGroup, _propDefinitionTools, _radioGroup2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _radioGroup.default,
    propDefinitions: _radioGroup2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_radioGroup2.propDefinitions), {
      value: 1,
      label: "How Cool Are You?"
    }),
    options: Array(10).fill().map(function (item, index) {
      return 1 + index;
    }),
    mostFavorite: 1,
    leastFavorite: 2,
    customChild: 5
  });

  _exports.default = _default;
});