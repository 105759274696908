define("ember-bootstrap-controls/routes/sorted", ["exports", "ember-bootstrap-controls/mixins/routes/sorted"], function (_exports, _sorted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SortedRoute = Ember.Route.extend(_sorted.default);
  var _default = SortedRoute;
  _exports.default = _default;
});