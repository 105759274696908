define("ember-bootstrap-controls/components/bootstrap/tables/-action-bar-header", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/tables/-action-bar-header"], function (_exports, _actionBarHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _actionBarHeader.default,
    classNames: ['row', 'align-items-end', 'justify-content-center', 'justify-content-md-between']
  });

  _exports.default = _default;
});