define("ember-bootstrap-controls/components/bootstrap/modal/header/-close-button", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/modal/header/-close-button"], function (_exports, _closeButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _closeButton.default
  });

  _exports.default = _default;
});