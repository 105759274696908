define('ember-authenticate-me/models/invite', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    creator: belongsTo('user', { async: true }),
    email: attr('string'),
    accepted: attr('string'),
    meta: attr(),

    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});