define('ember-prop-types/utils/logger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Logger = Ember.Logger;
  exports.default = {
    throwError: function throwError(message) {
      throw new Error(message);
    },


    /**
     * Log a warning message
     * @param {*} obj - the object doing the warning
     * @param {String} message - the warning
     * @param {Boolean} throwError - whether or not to throw error
     */
    warn: function warn(obj, message, throwError) {
      var id = obj.toString();
      message = '[' + id + ']: ' + message;

      if (throwError) {
        this.throwError(message);
      } else {
        Logger.warn(message);
      }
    }
  };
});