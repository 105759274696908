define("ember-bootstrap-controls/components/bootstrap/tables/action-bar/-action", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/tables/action-bar/-action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _action.default,
    classNames: ['px-2', 'px-md-1']
  });

  _exports.default = _default;
});