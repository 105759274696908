define('ember-freestyle/components/freestyle-guide', ['exports', 'ember-freestyle/templates/components/freestyle-guide'], function (exports, _freestyleGuide) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    layout: _freestyleGuide.default,
    classNames: ['FreestyleGuide'],

    emberFreestyle: inject.service(),
    highlightJsTheme: computed.alias('emberFreestyle.defaultTheme'),

    showMenu: computed.alias('emberFreestyle.showMenu'),
    showAside: false,

    actions: {
      toggleNav: function toggleNav() {
        this.toggleProperty('showMenu');
      },
      toggleAside: function toggleAside() {
        this.toggleProperty('showAside');
      }
    }
  });
});