define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/-password", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/-password", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-password"], function (_exports, _password, _propDefinitionTools, _password2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _password.default,
    propDefinitions: _password2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_password2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});