define("ember-bootstrap-controls/utils/assert-if-using-renamed-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = asserIfUsingRenamedEvents;

  function asserIfUsingRenamedEvents(component) {
    var keyPress = component.get('keyPress');

    if (keyPress) {
      assertEvent('keyPress');
    }

    var keyUp = component.get('keyUp');

    if (keyUp) {
      assertEvent('keyUp');
    }

    var keyDown = component.get('keyDown');

    if (keyDown) {
      assertEvent('keyDown');
    }
  }

  function assertEvent(eventName) {
    var dasherizedEventName = Ember.String.dasherize(eventName);
    (false && !(false) && Ember.assert("This event has been renamed from \"".concat(eventName, "\" to \"").concat(dasherizedEventName, "\", please rename occordingly")));
  }
});