define('ember-authenticate-me/mixins/routes/unauthenticate-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    beforeModel: function beforeModel() {
      var _this = this;

      var superReturn = this._super.apply(this, arguments);

      if (superReturn.then) {
        return this._super.apply(this, arguments).then(function () {
          return _this.get('session').close().catch(function () {/* noop */});
        });
      } else {
        return this.get('session').close().catch(function () {/* noop */});
      }
    }
  });
});