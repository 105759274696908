define('ember-freestyle/components/freestyle-notes', ['exports', 'ember-freestyle/templates/components/freestyle-notes'], function (exports, _freestyleNotes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    layout: _freestyleNotes.default,
    tagName: '',
    emberFreestyle: inject.service(),

    unindent: true,

    _unindent: function _unindent(snippet) {
      if (!this.get('unindent')) {
        return snippet;
      }
      var match = void 0,
          min = void 0,
          lines = snippet.split("\n").filter(function (l) {
        return l !== '';
      });
      for (var i = 0; i < lines.length; i++) {
        match = /^[ \t]*/.exec(lines[i]);
        if (match && (typeof min === 'undefined' || min > match[0].length)) {
          min = match[0].length;
        }
      }
      if (typeof min !== 'undefined' && min > 0) {
        snippet = snippet.replace(new RegExp("^[ \t]{" + min + "}", 'gm'), "");
      }
      return snippet;
    },

    text: computed('name', function () {
      return this._unindent((this.get('emberFreestyle.snippets')[this.get('name')] || '').replace(/^(\s*\n)*/, '').replace(/\s*$/, ''));
    })

  });
});