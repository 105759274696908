define("ember-bootstrap-controls/components/bootstrap-data-table", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-data-table"], function (_exports, _bootstrapDataTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bootstrapDataTable.default,
    tagName: '',
    dataArray: null,
    title: null,
    striped: null,
    responsive: null,
    bordered: null,
    canHover: null,
    condensed: null,
    numberedRows: null,
    hasLink: null,
    idOnRoute: null,
    url: null
  });

  _exports.default = _default;
});