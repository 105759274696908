define("ember-bootstrap-controls/components/freestyle/bootstrap/tables/body/row/-actions", ["exports", "ember-bootstrap-controls/components/freestyle/bootstrap/templates/components/freestyle/bootstrap/tables/body/row/-actions", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/bootstrap/tables/body/row/-actions"], function (_exports, _actions, _propDefinitionTools, _actions2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _actions.default,
    propDefinitions: _actions2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_actions2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});