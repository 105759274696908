define("ember-bootstrap-controls/components/bootstrap/modal/-header", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/modal/-header", "ember-prop-types", "ember-bootstrap-controls/utils/prop-definition-tools"], function (_exports, _header, _emberPropTypes, _propDefinitionTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.propDefinitions = void 0;
  var propDefinitions = {
    showCloseInHeader: {
      default: false,
      description: 'Indicates whether the header has a close button in the top left corner',
      type: _emberPropTypes.PropTypes.bool
    }
  };
  _exports.propDefinitions = propDefinitions;

  var _default = Ember.Component.extend({
    layout: _header.default,
    propTypes: (0, _propDefinitionTools.BuilderForPropTypes)(propDefinitions),
    classNames: ['modal-header'],
    getDefaultProps: function getDefaultProps() {
      return (0, _propDefinitionTools.BuilderForPropDefaults)(propDefinitions);
    }
  });

  _exports.default = _default;
});