define('ember-authenticate-me/torii-providers/traditional-authentication', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    name: 'traditional-authentication',

    configNamespace: Ember.computed('name', function () {
      return 'providers.' + this.get('name');
    }),

    open: function open(authenticationParams) {
      return new Ember.RSVP.Promise(function (resolve /*, reject*/) {
        resolve(authenticationParams);
      });
    },

    fetch: function fetch() /* options */{},

    close: function close() /* options */{}
  });
});