define('ember-authenticate-me/mixins/routes/authenticated', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isAuthenticated = isAuthenticated;
  function isAuthenticated(session, sessionLifecycle) {
    var authenticated = session.get('content.isAuthenticated');

    return new Ember.RSVP.Promise(function (resolve, reject) {
      var _arguments = arguments;

      if (!authenticated) {
        session.fetch().then(function () {
          sessionLifecycle.userLoggedIn(session);
          resolve.apply(undefined, _arguments);
        }).catch(function (e) {
          reject(e);
        });
      } else {
        resolve(session);
      }
    });
  }

  exports.default = Ember.Mixin.create({
    loginRoute: 'login',
    sessionLifecycle: Ember.inject.service('session-lifecycle'),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var session = this.get('session');

      return isAuthenticated(session, this.get('sessionLifecycle')).catch(function () {
        Ember.Logger.info("No user session, transitioning to login.");
        var loginController = _this.controllerFor('login');

        loginController.set('previousTransition', transition);
        _this.transitionTo('login');
      });
    },

    actions: {
      error: function error(_error, transition) {
        var _this2 = this;

        if (_error && _error.status === 401) {
          var loginController = this.controllerFor('login');

          loginController.set('previousTransition', transition);
          this.get('session').close().then(function () {
            _this2.transitionTo(_this2.get('loginRoute'));
          }).catch(function (error) {
            Ember.Logger.error('Error ' + error);
            _this2.transitionTo(_this2.get('loginRoute'));
          });
        }

        return true;
      }
    }
  });
});