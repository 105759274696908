define("ember-bootstrap-controls/components/freestyle/bootstrap/buttons/-button", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap/buttons/-button", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap/buttons/-button"], function (_exports, _button, _propDefinitionTools, _button2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _button.default,
    propDefinitions: _button2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_button2.propDefinitions), {
      value: ''
    }),
    basicValue: '',
    disabled: true,
    buttonText: 'Test',
    actions: {
      simpleAction: function simpleAction() {}
    }
  });

  _exports.default = _default;
});