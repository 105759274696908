define("ember-bootstrap-controls/utils/computed-action-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedActionKey;

  function computedActionKey(dependentActionKey) {
    var camelCaseActionKey = Ember.String.camelize(dependentActionKey);
    return Ember.computed(dependentActionKey, function () {
      return this.get(dependentActionKey) ? camelCaseActionKey : null;
    });
  }
});