define('ember-prop-types/utils/validators/index', ['exports', 'ember-prop-types/utils/validators/any', 'ember-prop-types/utils/validators/array', 'ember-prop-types/utils/validators/array-of', 'ember-prop-types/utils/validators/bool', 'ember-prop-types/utils/validators/date', 'ember-prop-types/utils/validators/element', 'ember-prop-types/utils/validators/ember-component', 'ember-prop-types/utils/validators/ember-object', 'ember-prop-types/utils/validators/func', 'ember-prop-types/utils/validators/instance-of', 'ember-prop-types/utils/validators/null', 'ember-prop-types/utils/validators/number', 'ember-prop-types/utils/validators/object', 'ember-prop-types/utils/validators/one-of', 'ember-prop-types/utils/validators/one-of-type', 'ember-prop-types/utils/validators/regexp', 'ember-prop-types/utils/validators/shape', 'ember-prop-types/utils/validators/string', 'ember-prop-types/utils/validators/symbol'], function (exports, _any, _array, _arrayOf, _bool, _date, _element, _emberComponent, _emberObject, _func, _instanceOf, _null, _number, _object, _oneOf, _oneOfType, _regexp, _shape, _string, _symbol) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assign = Ember.assign,
      merge = Ember.merge;


  var objectAssign = Object.assign || assign || merge;

  var validators = {
    any: _any.default,
    array: _array.default,
    bool: _bool.default,
    date: _date.default,
    element: _element.default,
    EmberComponent: _emberComponent.default,
    EmberObject: _emberObject.default,
    func: _func.default,
    instanceOf: _instanceOf.default,
    null: _null.default,
    number: _number.default,
    object: _object.default,
    oneOf: _oneOf.default,
    regexp: _regexp.default,
    string: _string.default,
    symbol: _symbol.default
  };

  objectAssign(validators, {
    arrayOf: _arrayOf.default.bind(undefined, validators),
    oneOfType: _oneOfType.default.bind(undefined, validators),
    shape: _shape.default.bind(undefined, validators)
  });

  exports.default = validators;
});