define("ember-bootstrap-controls/components/ember-bootstrap-controls-freestyle", ["exports", "ember-bootstrap-controls/templates/components/ember-bootstrap-controls-freestyle"], function (_exports, _emberBootstrapControlsFreestyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _emberBootstrapControlsFreestyle.default,
    actions: {
      selectItem: function selectItem(selectedItem) {
        this.set("selectedSample", selectedItem);
      },
      alertSelectItem: function alertSelectItem(selectedItem) {
        this.set("selectedSample", selectedItem);
      }
    }
  });

  _exports.default = _default;
});