define("ember-bootstrap-controls/components/freestyle-prop-types/-default", ["exports", "ember-bootstrap-controls/templates/components/freestyle-prop-types/-default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _default2.default
  });

  _exports.default = _default;
});