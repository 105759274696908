define("ember-bootstrap-controls/components/bootstrap-phone-mask-input", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-phone-mask-input", "ember-bootstrap-controls/mixins/components/inputable", "ember-bootstrap-controls/utils/assert-if-using-renamed-events"], function (_exports, _bootstrapPhoneMaskInput, _inputable, _assertIfUsingRenamedEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inputable.default, {
    classNames: ['form-group', 'bootstrap-input-component'],
    classNameBindings: ['hasSuccess:has-success', 'hasWarning:has-warning', 'showError:has-error'],
    layout: _bootstrapPhoneMaskInput.default,
    placeholder: '(___) ___-____',
    value: null,
    readonly: null,
    type: null,
    srOnly: false,
    tabindex: 0,
    required: true,
    init: function init() {
      this.phoneMask = '(999) 999-9999';

      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (0, _assertIfUsingRenamedEvents.default)(this);
    }
  });

  _exports.default = _default;
});