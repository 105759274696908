define("ember-bootstrap-controls/mixins/components/-inputable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNames: ['form-group'],
    classNameBindings: ['hasSuccess:has-success', 'hasWarning:has-warning', 'showError:has-error'],
    errors: undefined,
    hasSuccess: undefined,
    hasWarning: undefined,
    disabled: undefined,
    hasError: Ember.computed.alias('errors.length'),
    hasValue: Ember.computed('value', function () {
      var value = this.get('value');
      return value ? true : false;
    }),
    showError: Ember.computed('hasError', function () {
      var hasError = this.get('hasError');
      return !!hasError;
    }),
    inputId: Ember.computed(function () {
      return "bootstrap-control-input-".concat(Ember.guidFor(this));
    }),
    helpId: Ember.computed(function () {
      return "".concat(Ember.guidFor(this), "-help");
    })
  });

  _exports.default = _default;
});