define("ember-bootstrap-controls/components/freestyle-prop-types/-one-of", ["exports", "ember-bootstrap-controls/templates/components/freestyle-prop-types/-one-of"], function (_exports, _oneOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _oneOf.default,
    dataValue: '',
    init: function init() {
      this.options = [];

      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('options', this.get('propDefinition.type.valueOptions'));
      this.set('dataValue', this.get("data.".concat(this.get('propDefinition.name'))));
    },
    actions: {
      optionSelected: function optionSelected(value) {
        this.set('dataValue', value);
        this.set("data.".concat(this.get('propDefinition.name')), value);
      }
    }
  });

  _exports.default = _default;
});