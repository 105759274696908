define("ember-bootstrap-controls/components/bootstrap-multi-select-lazy", ["exports", "ember-bootstrap-controls/components/bootstrap-multi-select", "ember-bootstrap-controls/templates/components/bootstrap-multi-select-lazy", "ember-bootstrap-controls/utils/lazy-select-state"], function (_exports, _bootstrapMultiSelect, _bootstrapMultiSelectLazy, _lazySelectState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // We don't want a wrapping tag as this is a wrapper and the control-wrapper component will define the tag and bindings
    layout: _bootstrapMultiSelectLazy.default,
    classNames: [_bootstrapMultiSelect.MULTI_SELECT_CLASS_NAME],

    /* Required Attributes */
    searchByPage: undefined,

    /* Optional Attributes */
    debounceMS: undefined,
    _lazyState: undefined,
    init: function init() {
      // call super
      this._super.apply(this, arguments); // Set initial state


      this.set('_lazyState', _lazySelectState.default.create());
    },
    actions: {
      search: function search(term) {
        var lazyState = this.get('_lazyState');
        var searchByPage = this.get('searchByPage');
        var debounceMS = this.get('debounceMS');
        (false && !(searchByPage) && Ember.assert('Must pass a `searchByPage action to `bootstrap-multi-select-lazy`', searchByPage));
        return lazyState.search(term, searchByPage, debounceMS);
      }
    }
  });

  _exports.default = _default;
});