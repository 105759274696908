define("ember-bootstrap-controls/components/freestyle/bootstrap/tables/table-with-pagination", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap/tables/table-with-pagination", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap/tables/table-with-pagination", "faker"], function (_exports, _tableWithPagination, _propDefinitionTools, _tableWithPagination2, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var createFakeModels = function createFakeModels() {
    return Array.from({
      length: 10
    }, function () {
      return {
        number: _faker.default.random.number(),
        first: _faker.default.name.firstName(),
        last: _faker.default.name.lastName(),
        handle: "@".concat(_faker.default.internet.userName()),
        email: _faker.default.internet.email(),
        phone: _faker.default.phone.phoneNumber()
      };
    });
  };

  var _default = Ember.Component.extend({
    layout: _tableWithPagination.default,
    propDefinitions: _tableWithPagination2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_tableWithPagination2.propDefinitions), {
      value: '',
      noRowsMessage: 'No Recent Tasks',
      columns: [{
        headerLabel: '#',
        cellValueKey: 'number',
        columnKey: 'number',
        sortingCriteriaValue: 'number'
      }, {
        headerLabel: 'First',
        cellValueKey: 'first',
        columnKey: 'first',
        sortingCriteriaValue: 'first'
      }, {
        headerLabel: 'Last',
        cellValueKey: 'last',
        columnKey: 'last',
        sortingCriteriaValue: 'last'
      }, {
        headerLabel: 'Handle',
        cellValueKey: 'handle',
        columnKey: 'handle',
        sortingCriteriaValue: 'handle'
      }, {
        headerLabel: 'Email',
        cellValueKey: 'email',
        columnKey: 'email',
        sortingCriteriaValue: 'email'
      }],
      models: Ember.ArrayProxy.create({
        content: createFakeModels(),
        meta: {
          page: {
            current_iteration_count: -24,
            iteration_count_end: 25,
            iteration_count_start: 1,
            offset: "1",
            record_count: 25,
            size: "25",
            total_pages: 2,
            total_records: 49
          }
        }
      })
    }),
    basicValue: '',
    actions: {
      simpleAction: function simpleAction() {},
      clickAction: function clickAction(text) {
        window.alert("You clikced on item ".concat(text));
      },
      sortAction: function sortAction(sortCriteria) {
        window.alert("The table will be sorted by ".concat(sortCriteria));
      },
      onEdit: function onEdit() {
        window.alert("Edit table row");
      },
      onDelete: function onDelete() {
        window.alert("Delete table row");
      }
    }
  });

  _exports.default = _default;
});