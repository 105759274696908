define("ember-bootstrap-controls/components/freestyle/bootstrap-power-select-lazy", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-power-select-lazy"], function (_exports, _bootstrapPowerSelectLazy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _window = window,
      setTimeout = _window.setTimeout;
  var COLORS = ['Aqua', 'Black', 'Blue', 'Brown', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10', 'B11', 'B12', 'B12', 'B12', 'B12', 'B12', 'B12', 'B12', 'B12', 'B12', 'Cyan', 'Dark Blue', 'Fuchsia', 'Gray', 'Green', 'White', 'Magenta', 'Navy Blue', 'Orange', 'Purple', 'Red', 'Salmon', 'Teal', 'Violet', 'Yellow'];

  function fuzzySearch(array, searchString) {
    return array.filter(function (string) {
      return string.toLowerCase().includes(searchString.toLowerCase());
    });
  }

  var _default = Ember.Component.extend({
    layout: _bootstrapPowerSelectLazy.default,
    pageSize: 10,
    selected: undefined,
    multiSelectSelected: undefined,
    init: function init() {
      this._super.apply(this, arguments);
    },
    pageCount: function pageCount(results) {
      var pageSize = this.get('pageSize');
      return Math.ceil(results.length / pageSize);
    },

    /* Create fake async search task */
    fetchColors: function fetchColors(searchString, page) {
      var _this = this;

      var timeout = 1000;
      var pageSize = this.get('pageSize');
      return new Ember.RSVP.Promise(function (resolve) {
        setTimeout(function () {
          var fuzzyColors = fuzzySearch(COLORS, searchString);
          var pageColors = fuzzyColors.slice((page - 1) * pageSize, page * pageSize);
          resolve({
            options: pageColors,
            pageCount: _this.pageCount(fuzzyColors)
          });
        }, timeout);
      });
    },
    actions: {
      fetchColors: function fetchColors(searchString, page) {
        return this.fetchColors(searchString, page);
      }
    }
  });

  _exports.default = _default;
});