define('ember-freestyle/components/freestyle-usage-controls', ['exports', 'ember-freestyle/templates/components/freestyle-usage-controls'], function (exports, _freestyleUsageControls) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  var capitalize = Ember.String.capitalize;
  exports.default = Ember.Component.extend({
    layout: _freestyleUsageControls.default,
    tagName: '',
    emberFreestyle: inject.service(),
    showLabels: computed.alias('emberFreestyle.showLabels'),
    showNotes: computed.alias('emberFreestyle.showNotes'),
    showCode: computed.alias('emberFreestyle.showCode'),

    showHeader: true,
    showFocus: true,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', function () {
        _this.set('focus', _this.get('emberFreestyle.focus'));
      });
    },


    actions: {
      toggleUsage: function toggleUsage(usageType) {
        var usageProperty = 'emberFreestyle.show' + capitalize(usageType);
        this.toggleProperty(usageProperty);
      },
      setFocus: function setFocus() {
        this.set('emberFreestyle.focus', this.get('focus'));
      }
    }
  });
});