define("ember-bootstrap-controls/components/bootstrap/-form-controls/cancel-button", ["exports", "ember-bootstrap-controls/components/bootstrap-button", "ember-bootstrap-controls/templates/components/bootstrap/-form-controls/cancel-button"], function (_exports, _bootstrapButton, _cancelButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bootstrapButton.default.extend({
    layout: _cancelButton.default,
    classNames: ['btn-secondary'],
    click: function click(event) {
      event.preventDefault();
      return this.get('action')();
    }
  });

  _exports.default = _default;
});