define("ember-bootstrap-controls/components/bootstrap/-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Checkbox.extend({
    classNames: ['form-check-input'],
    click: function click() {
      var onClick = this.get('onClick');

      if (onClick) {
        onClick.apply(void 0, [!this.get('checked'), this.get('checked')].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});