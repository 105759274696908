define('ember-authenticate-me/routes/login', ['exports', 'ember-authenticate-me/mixins/routes/authenticated'], function (exports, _authenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sessionLifecycle: Ember.inject.service('session-lifecycle'),

    beforeModel: function beforeModel() /*transition*/{
      var _this = this;

      var loginController = this.controllerFor('login');
      var session = this.get('session');
      var sessionLifecycle = this.get('sessionLifecycle');

      return (0, _authenticated.isAuthenticated)(session, sessionLifecycle).then(function () {
        var previousTransition = loginController.get('previousTransition');

        if (previousTransition) {
          previousTransition.retry();
        } else {
          _this.transitionTo('/');
        }
      }).catch(function () {
        // no-op, we are not logged in, continue.
      });
    }
  });
});