define("ember-bootstrap-controls/components/bootstrap-pagination-nav/page-link", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-pagination-nav/page-link"], function (_exports, _pageLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _pageLink.default,
    tagName: 'a',
    classNames: ['page-link'],
    attributeBindings: ['tabindex', 'href'],
    href: '#!',
    //TODO: Add prop definitions
    disabled: undefined,
    active: undefined,
    click: function click(e) {
      e.preventDefault();
      var disabled = this.get('disabled');
      var active = this.get('active');

      if (!disabled && !active) {
        return this.get('action').apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});