define("ember-bootstrap-controls/components/freestyle-prop-types", ["exports", "ember-bootstrap-controls/templates/components/freestyle-prop-types"], function (_exports, _freestylePropTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var definedTypes = ['arrayOf', 'bool', 'instanceOf', 'number', 'oneOf', 'string', 'date'];

  var _default = Ember.Component.extend({
    layout: _freestylePropTypes.default,
    options: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var propDefinitions = this.get('propDefinitions');
      var properties = [];
      var componentName = '';
      var type = null;

      for (var key in propDefinitions) {
        if (propDefinitions.hasOwnProperty(key)) {
          componentName = 'freestyle-prop-types/-default';

          if (_typeof(propDefinitions[key].type) === 'object') {
            type = propDefinitions[key].type;
          } else if (typeof propDefinitions[key].type === 'function') {
            type = propDefinitions[key].type();
          } else {
            window.alert("Unknown type: " + type + " at key " + key + ". Double check your PropTypes");
          }

          if (definedTypes.includes(type.type)) {
            componentName = "freestyle-prop-types/-".concat(type.type.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase());
          }

          properties.push({
            componentName: componentName,
            description: propDefinitions[key].description,
            default: propDefinitions[key].default,
            name: key,
            required: type.required,
            type: type
          });
        }
      }

      properties.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      this.set('options', properties);
    }
  });

  _exports.default = _default;
});