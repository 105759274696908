define("ember-bootstrap-controls/components/bootstrap-input", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-input", "ember-bootstrap-controls/utils/assert-if-using-renamed-events"], function (_exports, _bootstrapInput, _assertIfUsingRenamedEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INPUT_CLASS_NAME = void 0;
  var INPUT_CLASS_NAME = 'bootstrap-input-component';
  _exports.INPUT_CLASS_NAME = INPUT_CLASS_NAME;

  var _default = Ember.Component.extend({
    layout: _bootstrapInput.default,
    tagName: '',
    classNames: [INPUT_CLASS_NAME],
    tabindex: 0,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('type') === 'checkbox') {
        (false && !(false) && Ember.assert("A type of 'checkbox' is not supported. Use  bootstrap-checkbox instead"));
      }

      (0, _assertIfUsingRenamedEvents.default)(this);
    }
  });

  _exports.default = _default;
});