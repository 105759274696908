define("ember-bootstrap-controls/components/bootstrap/modal/-body", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/modal/-body", "ember-bootstrap-controls/utils/prop-definition-tools"], function (_exports, _body, _propDefinitionTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.propDefinitions = void 0;
  var propDefinitions = {};
  _exports.propDefinitions = propDefinitions;

  var _default = Ember.Component.extend({
    layout: _body.default,
    propTypes: (0, _propDefinitionTools.BuilderForPropTypes)(propDefinitions),
    classNames: ['modal-body'],
    getDefaultProps: function getDefaultProps() {
      return (0, _propDefinitionTools.BuilderForPropDefaults)(propDefinitions);
    }
  });

  _exports.default = _default;
});