define('ember-prop-types/index', ['exports', 'ember-prop-types/mixins/prop-types'], function (exports, _propTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _propTypes.default;
    }
  });
  Object.defineProperty(exports, 'helpers', {
    enumerable: true,
    get: function () {
      return _propTypes.helpers;
    }
  });
  Object.defineProperty(exports, 'PropTypes', {
    enumerable: true,
    get: function () {
      return _propTypes.PropTypes;
    }
  });
});