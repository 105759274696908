define("ember-bootstrap-controls/mixins/routes/sorted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      this.queryParams = Object.assign({
        sortCriteria: {
          refreshModel: true
        },
        sortReverse: {
          refreshModel: true
        }
      }, this.get('queryParams'));
      return this._super.apply(this, arguments);
    },
    sortingParams: function sortingParams(params, defaultSortCriteria) {
      var defaultSortReverse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (params.sortReverse === true || params.sortReverse === false) {
        // NOTE: Convert T/F to string in order to send across server
        params.sortReverse = params.sortReverse.toString();
      }

      return {
        criteria: params.sortCriteria ? params.sortCriteria : defaultSortCriteria,
        reverse: params.sortReverse ? params.sortReverse : defaultSortReverse
      };
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('sortCriteria', null);
        controller.set('sortReverse', false);
      }

      return this._super.apply(this, arguments);
    },
    actions: {
      onSort: function onSort(sortCriteria, sortReverse) {
        var controller = this.get('controller');

        if (Ember.isPresent(controller)) {
          controller.setProperties({
            sortCriteria: sortCriteria,
            sortReverse: sortReverse,
            pageNumber: 1
          });
        }
      }
    }
  });

  _exports.default = _default;
});