define('ember-freestyle/components/freestyle-variant-list', ['exports', 'ember-freestyle/templates/components/freestyle-variant-list'], function (exports, _freestyleVariantList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _freestyleVariantList.default,

    actions: {
      onClickItem: function onClickItem(variantKey) {
        this.onClickVariant(variantKey);
      }
    }
  });
});