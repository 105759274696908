define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/-date", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/-date", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-date"], function (_exports, _date, _propDefinitionTools, _date2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _date.default,
    propDefinitions: _date2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_date2.propDefinitions), {
      value: new Date()
    })
  });

  _exports.default = _default;
});