define('ember-authenticate-me/components/user-account-inputs', ['exports', 'ember-authenticate-me/templates/components/user-account-inputs'], function (exports, _userAccountInputs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _userAccountInputs.default,
    tagName: ''
  });
});