define("ember-bootstrap-controls/components/freestyle/bootstrap/tables/action-bar/-action", ["exports", "ember-bootstrap-controls/components/freestyle/templates/components/freestyle/bootstrap/tables/action-bar/-action", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/freestyle/bootstrap/bootstrap/tables/action-bar/-action"], function (_exports, _action, _propDefinitionTools, _action2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _action.default,
    propDefinitions: _action2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_action2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});