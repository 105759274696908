define("ember-bootstrap-controls/components/freestyle/bootstrap-mask-input", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-mask-input"], function (_exports, _bootstrapMaskInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bootstrapMaskInput.default,
    value: null,
    inputId: 1,
    type: "text",
    readonly: false,
    disabled: false,
    showMaskOnHover: false,
    showMaskOnFocus: false,
    init: function init() {
      this.myMask = '(999) 999-999';

      this._super.apply(this, arguments);
    },
    actions: {
      keyPress: function keyPress() {
        console.log('key pressed:');
      },
      keyUp: function keyUp() {
        console.log('key up');
      },
      keyDown: function keyDown() {
        console.log('key down');
      },
      focusOut: function focusOut() {
        console.log('focus out');
      }
    }
  });

  _exports.default = _default;
});