define("ember-bootstrap-controls/components/bootstrap-date-picker", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-date-picker"], function (_exports, _bootstrapDatePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DATEPICKER_CLASS_NAME = void 0;
  var DATEPICKER_CLASS_NAME = 'bootstrap-date-picker-component';
  _exports.DATEPICKER_CLASS_NAME = DATEPICKER_CLASS_NAME;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: [DATEPICKER_CLASS_NAME],
    layout: _bootstrapDatePicker.default,
    todayHighlight: true,
    format: 'mm/dd/yyyy'
  });

  _exports.default = _default;
});