define("ember-bootstrap-controls/components/bootstrap/simple-form/-controls", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/simple-form/-controls"], function (_exports, _controls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _controls.default
  });

  _exports.default = _default;
});