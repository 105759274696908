define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/-floating-label-input", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/-floating-label-input", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-floating-label-input"], function (_exports, _floatingLabelInput, _propDefinitionTools, _floatingLabelInput2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _floatingLabelInput.default,
    propDefinitions: _floatingLabelInput2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_floatingLabelInput2.propDefinitions), {
      value: '',
      label: 'Advanced Example'
    }),
    basicValue: ''
  });

  _exports.default = _default;
});