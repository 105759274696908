define("ember-tui-editor/components/tui-editor", ["exports", "ember-tui-editor/templates/components/tui-editor"], function (_exports, _tuiEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    layout: _tuiEditor.default,
    value: '',
    // here we use a syntax like <property>:<method>:<tui option> to update such property (optional)>
    tuiOptions: Object.freeze(['previewStyle:changePreviewStyle', 'editType:changeMode:initialEditType', 'height:height', 'minHeight:minHeight', 'language', 'useDefaultHTMLSanitizer', 'useCommandShortcut', 'codeBlockLanguages', 'usageStatistics', 'toolbarItems', 'hideModeSwitch', 'viewer', 'value:setValue:initialValue', 'hooks']),
    // gathers all the options to initialize TUI editor, respecting tuiOptions syntax
    options: Ember.computed('tuiOptions.[]', function () {
      var _this = this;

      var options = {};
      this.get('tuiOptions').forEach(function (o) {
        var _o$split = o.split(':'),
            _o$split2 = _slicedToArray(_o$split, 3),
            optionName = _o$split2[0],
            tuiOption = _o$split2[2];

        tuiOption = tuiOption ? tuiOption : optionName;

        var value = _this.get(optionName);

        if (value !== undefined) {
          options[tuiOption] = value;
        }
      });
      return options;
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.setupEditor().then(function () {
        return _this2.addObservers();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.removeObservers();
    },
    setupEditor: function setupEditor() {
      var _this3 = this;

      var options = this.get('options');
      return emberAutoImportDynamic("tui-editor").then(function (module) {
        return _this3.set('editor', module.default.factory(Ember.assign(options, {
          el: _this3.element,
          events: {
            load: function load() {
              for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
              }

              return _this3.eventInvoked.apply(_this3, ['onLoad'].concat(args));
            },
            change: function change() {
              for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
                args[_key2] = arguments[_key2];
              }

              return _this3.eventInvoked.apply(_this3, ['onChange', _this3.editor.getValue()].concat(args));
            },
            stateChange: function stateChange() {
              for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
                args[_key3] = arguments[_key3];
              }

              return _this3.eventInvoked.apply(_this3, ['onStateChange'].concat(args));
            },
            focus: function focus() {
              for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
                args[_key4] = arguments[_key4];
              }

              return _this3.eventInvoked.apply(_this3, ['onFocus'].concat(args));
            },
            blur: function blur() {
              for (var _len5 = arguments.length, args = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
                args[_key5] = arguments[_key5];
              }

              return _this3.eventInvoked.apply(_this3, ['onBlur'].concat(args));
            }
          }
        })));
      });
    },
    // tests if an `actionName` function exists and calls it with the arguments if so
    eventInvoked: function eventInvoked(actionName) {
      if (this.get(actionName)) {
        for (var _len6 = arguments.length, args = new Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
          args[_key6 - 1] = arguments[_key6];
        }

        this.get(actionName).apply(void 0, args);
      }
    },
    addObservers: function addObservers() {
      var _this4 = this;

      this._observers = {};
      this.get('tuiOptions').forEach(function (o) {
        var _o$split3 = o.split(':'),
            _o$split4 = _slicedToArray(_o$split3, 2),
            optionName = _o$split4[0],
            tuiMethod = _o$split4[1];

        if (tuiMethod) {
          _this4._observers[optionName] = function () {
            var value = this.get(optionName); // `value` is a special case because using `setValue`
            // moves the current cursor position so we need to avoid calling it
            // only call it when the editor value is different from the new value we got

            if (optionName === 'value' && this.editor.getValue) {
              var editorValue = this.editor.getValue();

              if (editorValue !== value) {
                this.editor.setValue(value);
              }
            } else {
              (false && !(!!this.editor[tuiMethod]) && Ember.assert("Editor instance should be have a function '".concat(tuiMethod, "' but found ").concat(this.editor[tuiMethod], " instead."), !!this.editor[tuiMethod]));
              this.editor[tuiMethod].call(this.editor, value);
            }
          };

          _this4.addObserver(optionName, _this4, _this4._observers[optionName]);
        }
      });
    },
    removeObservers: function removeObservers() {
      var _this5 = this;

      if (this._observers) {
        this.get('tuiOptions').forEach(function (o) {
          var _o$split5 = o.split(':'),
              _o$split6 = _slicedToArray(_o$split5, 1),
              optionName = _o$split6[0];

          if (_this5._observers[optionName]) {
            _this5.removeObserver(optionName, _this5, _this5._observers[optionName]);

            delete _this5._observers[optionName];
          }
        });
      }
    }
  });

  _exports.default = _default;
});