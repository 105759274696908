define("ember-bootstrap-controls/helpers/parse-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseColumns = parseColumns;
  _exports.default = void 0;

  function parseColumns(params
  /*, hash*/
  ) {
    var record = params[0];
    var attrName = params[1];

    if (record && attrName) {
      var result = record.get(attrName);
      return new Ember.String.htmlSafe(result);
    }
  }

  var _default = Ember.Helper.helper(parseColumns);

  _exports.default = _default;
});