define("ember-bootstrap-controls/components/bootstrap/tables/body/row/actions/-edit", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/tables/body/row/actions/-edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _edit.default,
    tagName: ''
  });

  _exports.default = _default;
});