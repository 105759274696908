define('ember-freestyle/services/ember-freestyle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Promise = Ember.RSVP.Promise;
  exports.default = Ember.Service.extend({
    showLabels: true,
    showNotes: true,
    showCode: true,

    showMenu: true,

    defaultTheme: 'zenburn',

    // must be explicitly set to null here for (query-params s=null ss=null f=null) to work
    section: null,
    subsection: null,
    focus: null,

    notFocused: computed.not('focus'),

    hljsVersion: '9.12.0',
    hljsPromise: null,
    hljsLanguagePromises: {},

    hljsUrl: function hljsUrl() {
      return 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/' + this.hljsVersion + '/highlight.min.js';
    },
    hljsThemeUrl: function hljsThemeUrl(theme) {
      return 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/' + this.hljsVersion + '/styles/' + theme + '.min.css';
    },
    hljsLanguageUrl: function hljsLanguageUrl(language) {
      return 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/' + this.hljsVersion + '/languages/' + language + '.min.js';
    },
    ensureHljs: function ensureHljs() {
      var _this = this;

      if (!this.hljsPromise) {
        this.hljsPromise = new Promise(function (resolve) {
          return Ember.$.getScript(_this.hljsUrl()).done(function (script) {
            resolve(script);
          });
        });
      }
      return this.hljsPromise;
    },
    highlight: function highlight(el) {
      this.ensureHljs().then(function () {
        hljs.highlightBlock(el);
      });
    },
    ensureHljsTheme: function ensureHljsTheme(theme) {
      if (Ember.$('[data-hljs-theme=' + theme + ']')[0]) {
        return;
      }

      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = this.hljsThemeUrl(theme);
      link.setAttribute('data-hljs-theme', '' + theme);

      document.head.appendChild(link);
    },
    ensureHljsLanguage: function ensureHljsLanguage(language) {
      var _this2 = this;

      if (!this.hljsLanguagePromises[language]) {
        this.hljsLanguagePromises[language] = new Promise(function (resolve) {
          return Ember.$.getScript(_this2.hljsLanguageUrl(language)).done(function (script) {
            resolve(script);
          });
        });
      }
      return this.hljsLanguagePromises[language];
    },


    // menu - tree structure of named sections with named subsections

    registerSection: function registerSection(sectionName) {
      var subsectionName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      var menu = this.get('menu') || Ember.A([]);
      if (!menu.filterBy('name', sectionName).length) {
        menu.push({
          name: sectionName,
          subsections: Ember.A([])
        });
      }
      if (Ember.isPresent(subsectionName)) {
        var section = menu.findBy('name', sectionName);
        if (!section.subsections.filterBy('name', subsectionName).length) {
          section.subsections.push({
            name: subsectionName
          });
        }
      }
      this.set('menu', menu);
    }
  });
});