define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/-checkbox", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/-checkbox", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-checkbox"], function (_exports, _checkbox, _propDefinitionTools, _checkbox2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _checkbox.default,
    propDefinitions: _checkbox2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_checkbox2.propDefinitions), {
      value: false,
      label: 'Sign Me Up!'
    }),
    basicValue: false,
    actions: {
      simpleClick: function simpleClick(newValue, oldValue, eventArguments) {
        window.alert("Going from ".concat(oldValue, " to ").concat(newValue, " from Event: ").concat(eventArguments));
      }
    }
  });

  _exports.default = _default;
});