define("ember-bootstrap-controls/components/bootstrap/control-wrapper", ["exports", "ember-bootstrap-controls/mixins/components/-inputable", "ember-bootstrap-controls/templates/components/bootstrap/control-wrapper"], function (_exports, _inputable, _controlWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inputable.default, {
    layout: _controlWrapper.default,
    classNames: ['form-group'],
    classNameBindings: ['floatingLabel:form-label-group']
  });

  _exports.default = _default;
});