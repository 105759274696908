define("ember-bootstrap-controls/components/freestyle/bootstrap-debounce-input", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-debounce-input"], function (_exports, _bootstrapDebounceInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bootstrapDebounceInput.default,
    searchCompleted: false,
    actions: {
      searchAction: function searchAction(text) {
        this.set('searchCompleted', true);
        this.set('searchText', text);
      }
    }
  });

  _exports.default = _default;
});