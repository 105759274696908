define("ember-bootstrap-controls/utils/prop-definition-tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BuilderForPropTypes = BuilderForPropTypes;
  _exports.BuilderForPropDefaults = BuilderForPropDefaults;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function BuilderForPropTypes(propDefinitions) {
    var types = {};

    for (var key in propDefinitions) {
      if (propDefinitions.hasOwnProperty(key)) {
        types[key] = propDefinitions[key].type;
      }
    }

    return types;
  }

  function BuilderForPropDefaults(propDefinitions) {
    var defaults = {};

    for (var key in propDefinitions) {
      if (propDefinitions.hasOwnProperty(key) && _typeof(propDefinitions[key].default) !== undefined) {
        defaults[key] = propDefinitions[key].default;
      }
    }

    return defaults;
  }
});