define("ember-bootstrap-controls/components/bootstrap/tables/-action-bar", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/tables/-action-bar"], function (_exports, _actionBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _actionBar.default,
    classNames: ['col', 'col-auto', 'mt-3', 'mb-1', 'my-md-2', 'order-2', 'order-md-1']
  });

  _exports.default = _default;
});