define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/-search", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/-search", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-search"], function (_exports, _search, _propDefinitionTools, _search2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _search.default,
    propDefinitions: _search2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_search2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});