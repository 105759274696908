define("ember-bootstrap-controls/utils/generate-uuid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateUUID;
  var _uuid = 0;

  function generateUUID() {
    return _uuid++;
  }
});