define('ember-prop-types/utils/validators/instance-of', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (ctx, name, value, def, logErrors, throwErrors) {
    var type = def.typeDef;
    var valid = value instanceof type;

    if (!valid && logErrors) {
      var typeString = type.toString();
      var nameOfTypeMatch = typeString.match(/function (\w*)/);
      var nameOfType = nameOfTypeMatch ? nameOfTypeMatch[1] : typeString;
      _logger.default.warn(ctx, 'Expected property ' + name + ' to be an instance of ' + nameOfType + ' but instead got: ' + typeOf(value), throwErrors);
    }

    return valid;
  };

  var typeOf = Ember.typeOf;
});