define("ember-bootstrap-controls/components/bootstrap-mask-input", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-mask-input", "ember-bootstrap-controls/components/bootstrap-input"], function (_exports, _bootstrapMaskInput, _bootstrapInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INPUT_MASK_CLASS_NAME = void 0;
  var INPUT_MASK_CLASS_NAME = 'bootstrap-input-mask-component';
  _exports.INPUT_MASK_CLASS_NAME = INPUT_MASK_CLASS_NAME;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: [_bootstrapInput.INPUT_CLASS_NAME, INPUT_MASK_CLASS_NAME],
    layout: _bootstrapMaskInput.default,
    keepCharPositions: true,
    actions: {
      update: function update(unmasked, masked) {
        Ember.set(this, 'value', unmasked);
        Ember.set(this, 'masked', masked);

        if (this.onUpdate) {
          this.onUpdate(unmasked, masked);
        }
      }
    }
  });

  _exports.default = _default;
});