define("ember-bootstrap-controls/helpers/minus-numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.minusNumbers = minusNumbers;
  _exports.default = void 0;

  function minusNumbers(params) {
    var result = params[0];

    for (var i = 1; i < params.length; i++) {
      result = result - params[i];
    }

    return result;
  }

  var _default = Ember.Helper.helper(minusNumbers);

  _exports.default = _default;
});