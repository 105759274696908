define('ember-js-pdf/components/js-pdf', ['exports', 'ember-js-pdf/templates/components/js-pdf', 'ember-js-pdf/commands'], function (exports, _jsPdf, _commands) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var keys = Object.keys;
  var isArray = Array.isArray;


  var JsPdfComponent = Ember.Component.extend({
    layout: _jsPdf.default,
    classNames: ['ember-js-pdf'],

    /**
     * Saved filename of generated PDF
     * @type {String}
     */
    filename: Ember.computed({
      get: function get() {
        return (Ember.get(this, 'elementId') || 'no-name') + '.pdf';
      },
      set: function set(key) {
        var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';


        // Enforce string as filename
        value = '' + value;

        // Enforce `.pdf` file type
        if (value.search(/\.pdf$/i) === -1) {
          value = value + '.pdf';
        }

        return value;
      }
    }),

    /**
     * Is PDF document rendered
     * @type {Boolean}
     */
    showPdf: true,

    /**
     * PDF frame width
     * @type {String}
     */
    width: '100%',

    /**
     * PDF frame height
     * @type {String}
     */
    height: '500px',

    /**
     * PDF document orientation
     * @type {String}
     */
    orientation: 'p',

    /**
     * Measurement unit used
     * @type {String}
     */
    unit: 'mm',

    /**
     * PDF page formats
     * @type {String}
     */
    format: 'a4',

    /**
     * Whether to compress output pdf
     * @type {Boolean}
     */
    compressPdf: false,

    /**
     * Current object generated from new jsPDF()
     * @type {Object}
     */
    content: Ember.computed('steps.[]', 'orientation', 'unit', 'format', 'compressPdf', {
      get: function get() {
        var _EmberGetProperties = Ember.getProperties(this, 'orientation', 'unit', 'format', 'compressPdf'),
            orientation = _EmberGetProperties.orientation,
            unit = _EmberGetProperties.unit,
            format = _EmberGetProperties.format,
            compressPdf = _EmberGetProperties.compressPdf;

        (false && !(typeof orientation === 'string' && orientation.length) && Ember.assert('{{js-pdf}} requires a valid PDF `orientation`', typeof orientation === 'string' && orientation.length));
        (false && !(typeof unit === 'string' && unit.length) && Ember.assert('{{js-pdf}} requires a measurment as `unit`', typeof unit === 'string' && unit.length));
        (false && !(typeof format === 'string' && format.length) && Ember.assert('{{js-pdf}} requires a valid page `format`', typeof format === 'string' && format.length));


        return new jsPDF(orientation, unit, format, compressPdf);
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * Base64 encoding of PDF document
     * @type {String}
     */
    src: Ember.computed('steps.[]', 'orientation', 'unit', 'format', 'compressPdf', function () {
      var jsPdf = Ember.get(this, 'content');
      var steps = Ember.get(this, 'steps');

      (false && !(isArray(steps)) && Ember.assert('{{js-pdf}} requires an array of rendering steps', isArray(steps)));

      addStepsToJsPdf(jsPdf, steps);

      return jsPdf.output('dataurlstring');
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.set(this, 'content', null);
    },


    actions: {
      onSave: function onSave() {
        var filename = Ember.get(this, 'filename');
        var action = Ember.get(this, 'onSave');
        var jsPdfInstance = Ember.get(this, 'content');
        var src = Ember.get(this, 'src'); // ensure src computes

        if (action) {
          action(filename, src);
        }

        if (jsPdfInstance) {
          jsPdfInstance.save(filename);
        }
      }
    }
  });

  exports.default = JsPdfComponent.reopenClass({
    positionalParams: ['steps']
  });


  /**
   * Apply PDF rendering steps to a jsPDF Object
   * @param {Object} pdf        pdf generated from new jsPDF()
   * @param {Array}  steps      List of steps to render PDF
   *                            ie: [{text: [35, 25, 'pdf text'] }]
   */
  function addStepsToJsPdf(pdf) {
    var steps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    var _loop = function _loop(i) {
      keys(steps[i]).forEach(function (command) {
        (false && !(_commands.default.indexOf(command) > -1) && Ember.assert('{{js-pdf}} steps is given valid command: ' + command, _commands.default.indexOf(command) > -1));


        var args = steps[i][command];
        if (!isArray(args)) args = [args];

        pdf[command].apply(pdf, _toConsumableArray(args));
      });
    };

    for (var i = 0; i < steps.length; i++) {
      _loop(i);
    }
  }
});