define("ember-bootstrap-controls/components/freestyle-prop-types/-date", ["exports", "ember-bootstrap-controls/templates/components/freestyle-prop-types/-date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _date.default,
    dataValue: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('dataValue', this.get("data.".concat(this.get('propDefinition.name'))));
    },
    actions: {
      valueUpdated: function valueUpdated() {
        this.set("data.".concat(this.get('propDefinition.name')), this.get('dataValue'));
      }
    }
  });

  _exports.default = _default;
});