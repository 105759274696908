define("ember-bootstrap-controls/components/freestyle/bootstrap/simple-select", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap/simple-select", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap/simple-select"], function (_exports, _simpleSelect, _propDefinitionTools, _simpleSelect2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _simpleSelect.default,
    propDefinitions: _simpleSelect2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_simpleSelect2.propDefinitions), {
      value: 5,
      label: "How Cool Are You?"
    }),
    options: Array(10).fill().map(function (item, index) {
      return 1 + index;
    }),
    mostFavorite: 1
  });

  _exports.default = _default;
});