define("ember-bootstrap-controls/components/bootstrap/-help", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/-help", "ember-prop-types"], function (_exports, _help, _emberPropTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _help.default,
    tagName: 'small',
    classNames: ['text-muted'],
    attributeBindings: ['id'],
    propTypes: {
      // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      id: _emberPropTypes.PropTypes.string.isRequired,
      help: _emberPropTypes.PropTypes.string.isRequired
    }
  });

  _exports.default = _default;
});