define("ember-bootstrap-controls/components/freestyle/bootstrap/tables/body/row/actions/-delete", ["exports", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/templates/components/freestyle/bootstrap/tables/body/row/actions/-delete", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/body/bootstrap/tables/body/row/actions/-delete"], function (_exports, _delete, _propDefinitionTools, _delete2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _delete.default,
    propDefinitions: _delete2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_delete2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});