define('ember-authenticate-me/components/forgot-password-form', ['exports', 'ember-authenticate-me/templates/components/forgot-password-form', 'ember-authenticate-me/configuration'], function (exports, _forgotPasswordForm, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.forgotPassword = forgotPassword;
  function forgotPassword(email) {
    var emberAuthenticateMeConfig = (0, _configuration.default)();
    var PASSWORD_RESET_URI = emberAuthenticateMeConfig.passwordResetUri || '/api/password_resets';

    return new Ember.RSVP.Promise(function (resolve, reject) {
      var ajaxHash = {
        type: "POST",
        url: PASSWORD_RESET_URI,
        data: {
          email: email
        }
      };

      ajaxHash.success = function () {
        resolve.apply(undefined, arguments);
      };

      ajaxHash.error = function () {
        reject.apply(undefined, arguments);
      };

      Ember.$.ajax(ajaxHash);
    });
  }

  exports.default = Ember.Component.extend({
    layout: _forgotPasswordForm.default,
    tagName: '',

    requestSent: false,
    _isSending: false,

    resetRequestCompleted: function resetRequestCompleted() {
      if (!this.isDestroyed) {
        this.setProperties({
          requestSent: true
        });
      }
    },


    forgotPassword: forgotPassword,

    actions: {
      resetPassword: function resetPassword(email) {
        var _this = this;

        this.set('_isSending', true);

        this.set('errors', null);

        return this.forgotPassword(email).then(function (rval) {
          _this.resetRequestCompleted();

          return rval;
        }).catch(function (e) {
          if (!_this.isDestroyed) {
            try {
              var errorResponse = JSON.parse(e.responseText);

              _this.set('errors', errorResponse.errors);
            } catch (parseError) {
              _this.set('errors', {});
            }
          }

          throw e;
        }).finally(function () {
          if (!_this.isDestroyed) {
            _this.set('_isSending', false);
          }
        });
      }
    }
  });
});