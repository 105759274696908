define("ember-bootstrap-controls/components/freestyle/bootstrap-currency-input", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-currency-input"], function (_exports, _bootstrapCurrencyInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bootstrapCurrencyInput.default,
    value: null,
    disabled: false,
    clearMaskOnLostFocus: false
  });

  _exports.default = _default;
});