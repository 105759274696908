define("ember-bootstrap-controls/components/bootstrap/simple-form", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/simple-form", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-prop-types"], function (_exports, _simpleForm, _propDefinitionTools, _emberPropTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.propDefinitions = void 0;
  var propDefinitions = {
    disabled: {
      description: 'Indicates whether the form and associated controls are disabled',
      type: _emberPropTypes.PropTypes.bool,
      default: false
    },
    submitAction: {
      description: 'Function to be ran when the submit button is clicked',
      type: _emberPropTypes.PropTypes.function,
      default: null
    }
  };
  _exports.propDefinitions = propDefinitions;

  var _default = Ember.Component.extend({
    layout: _simpleForm.default,
    tagName: 'form',
    propTypes: (0, _propDefinitionTools.BuilderForPropTypes)(propDefinitions),
    getDefaultProps: function getDefaultProps() {
      return (0, _propDefinitionTools.BuilderForPropDefaults)(propDefinitions);
    }
  });

  _exports.default = _default;
});