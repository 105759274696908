define('ember-authenticate-me/adapters/base', ['exports', 'ember-authenticate-me/adapters/authenticated'], function (exports, _authenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticated.default.extend({
    namespace: 'token_authenticate_me/api/v1',

    pathForType: function pathForType(modelName) {
      var modelNameSplit = modelName.split('/');
      var underscored = Ember.String.underscore(modelNameSplit.length === 2 ? modelNameSplit[1] : modelNameSplit[0]);

      return Ember.String.pluralize(underscored);
    }
  });
});