define('ember-authenticate-me/add-routes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = addRoutes;
  function addRoutes(router) {
    router.route('login');
    router.route('logout');
    router.route('sign-up');
    router.route('reset-password', { path: '/reset-password/:token' });
    router.route('forgot-password');
    router.route('edit-account', { path: '/edit-account/:user_id' });
  }
});