define("ember-bootstrap-controls/components/freestyle/bootstrap/modal/-modal", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap/modal/-modal", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap/modal/-modal"], function (_exports, _modal, _propDefinitionTools, _modal2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _modal.default,
    propDefinitions: _modal2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_modal2.propDefinitions), {
      value: '',
      title: 'Advanced Modal',
      body: 'Advanced Example Modal Body Text'
    }),
    isModalOpen: false,
    isModalOpenAdvanced: false,
    isModalOpenCustomSub: false,
    actions: {
      closeModal: function closeModal() {
        alert('freestyle: closeModal');
      },
      closeModalAdvanced: function closeModalAdvanced() {
        alert('freestyle: closeModalAdvanced');
      },
      closeModalCustomSub: function closeModalCustomSub() {
        alert('freestyle: closeModalCustomSub');
        this.set('isModalOpenCustomSub', false);
      },
      acceptModal: function acceptModal() {
        alert('freestyle: acceptModal');
        this.set('isModalOpen', false);
      },
      acceptModalAdvanced: function acceptModalAdvanced() {
        alert('freestyle: acceptModalAdvanced');
        this.set('data.isOpen', false);
      },
      acceptModalCustomSub: function acceptModalCustomSub() {
        alert('freestyle: acceptModalCustomSub');
        this.set('isModalOpenCustomSub', false);
      },
      openModal: function openModal() {
        this.set('isModalOpen', true);
      },
      openModalAdvanced: function openModalAdvanced() {
        this.set('data.isOpen', true);
      },
      openModalCustomSub: function openModalCustomSub() {
        this.set('isModalOpenCustomSub', true);
      }
    }
  });

  _exports.default = _default;
});