define("ember-bootstrap-controls/components/bootstrap-pagination-nav", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-pagination-nav"], function (_exports, _bootstrapPaginationNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['bootstrap-pagination-nav-component'],
    classNameBindings: ['aria-label'],
    layout: _bootstrapPaginationNav.default,
    // Attributes
    'aria-label': 'Page navigation',
    pageNumber: null,
    totalPages: null,
    innerWindowSize: 2,
    outerWindowSize: 1,
    // Actions
    moveToPage: null,
    _innerPageNumbers: Ember.computed('innerWindowSize', 'pageNumber', function () {
      var innerWindowSize = this.get('innerWindowSize');
      var arraySize = innerWindowSize * 2 + 1; // double the length plus the middle "pageNumber"

      var pageNumber = this.get('pageNumber');
      return Array.apply(null, Array(arraySize)).map(function (n, index) {
        return pageNumber - innerWindowSize + index;
      });
    }),
    _outerLeftPageNumbers: Ember.computed('outerWindowSize', function () {
      var outerWindowSize = this.get('outerWindowSize');
      return Array.apply(null, Array(outerWindowSize)).map(function (n, index) {
        return index + 1;
      });
    }),
    _outerRightPageNumbers: Ember.computed('outerWindowSize', 'totalPages', function () {
      var outerWindowSize = this.get('outerWindowSize');
      var totalPages = this.get('totalPages');
      return Array.apply(null, Array(outerWindowSize)).map(function (n, index) {
        return totalPages - outerWindowSize + index + 1;
      });
    }),
    visibleOuterLeftPageNumbers: Ember.computed('_outerLeftPageNumbers', 'totalPages', function () {
      var totalPages = this.get('totalPages');

      var _outerLeftPageNumbers = this.get('_outerLeftPageNumbers');

      return _outerLeftPageNumbers.filter(function (pageNumber) {
        return pageNumber <= totalPages;
      });
    }),
    visibleOuterRightPageNumbers: Ember.computed('_outerRightPageNumbers', 'visibleOuterLeftPageNumbers', function () {
      var _outerRightPageNumbers = this.get('_outerRightPageNumbers');

      var visibleOuterLeftPageNumbers = this.get('visibleOuterLeftPageNumbers');
      return _outerRightPageNumbers.filter(function (pageNumber) {
        return pageNumber >= 1 && visibleOuterLeftPageNumbers.indexOf(pageNumber) === -1;
      });
    }),
    visibleInnerPageNumbers: Ember.computed('_innerPageNumbers', 'visibleOuterLeftPageNumbers', 'visibleOuterRightPageNumbers', 'pageNumber', 'totalPages', function () {
      var _innerPageNumbers = this.get('_innerPageNumbers');

      var visibleOuterLeftPageNumbers = this.get('visibleOuterLeftPageNumbers');
      var visibleOuterRightPageNumbers = this.get('visibleOuterRightPageNumbers');
      var totalPages = this.get('totalPages');
      return _innerPageNumbers.filter(function (pageNumber) {
        return visibleOuterLeftPageNumbers.indexOf(pageNumber) === -1 && visibleOuterRightPageNumbers.indexOf(pageNumber) === -1 && pageNumber >= 1 && pageNumber <= totalPages;
      });
    }),
    hasLeftGap: Ember.computed('pageNumber', 'innerWindowSize', 'outerWindowSize', function () {
      var innerWindowSize = this.get('innerWindowSize');
      var outerWindowSize = this.get('outerWindowSize');
      var pageNumber = this.get('pageNumber');
      var firstInnerPageNumber = pageNumber - innerWindowSize;
      return firstInnerPageNumber > outerWindowSize + 1;
    }),
    hasRightGap: Ember.computed('pageNumber', 'totalPages', 'innerWindowSize', 'outerWindowSize', function () {
      var innerWindowSize = this.get('innerWindowSize');
      var outerWindowSize = this.get('outerWindowSize');
      var totalPages = this.get('totalPages');
      var pageNumber = this.get('pageNumber');
      var lastInnerPageNumber = pageNumber + innerWindowSize;
      var firstOuterRightPageNumber = totalPages - outerWindowSize;
      return lastInnerPageNumber < firstOuterRightPageNumber;
    }),
    actions: {
      moveToPreviousPage: function moveToPreviousPage() {
        var pageNumber = this.get('pageNumber');

        if (pageNumber > 1) {
          this.get('moveToPage')(pageNumber - 1);
        }
      },
      moveToNextPage: function moveToNextPage() {
        var pageNumber = this.get('pageNumber');
        var totalPages = this.get('totalPages');

        if (pageNumber < totalPages) {
          this.get('moveToPage')(pageNumber + 1);
        }
      },
      moveToPage: function moveToPage(pageNumber) {
        if (pageNumber >= 1) {
          this.get('moveToPage')(pageNumber);
        }
      }
    }
  });

  _exports.default = _default;
});