define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/-range", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/-range", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-range"], function (_exports, _range, _propDefinitionTools, _range2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _range.default,
    propDefinitions: _range2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_range2.propDefinitions), {
      value: 1,
      label: 'range control'
    }),
    basicValue: 1
  });

  _exports.default = _default;
});