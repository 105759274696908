define("ember-bootstrap-controls/components/freestyle-prop-types/-number", ["exports", "ember-bootstrap-controls/templates/components/freestyle-prop-types/-number"], function (_exports, _number) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _number.default,
    dataValue: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('dataValue', this.get("data.".concat(this.get('propDefinition.name'))));
    },
    actions: {
      numberUpdated: function numberUpdated(value) {
        this.set('dataValue', value);
        this.set("data.".concat(this.get('propDefinition.name')), value);
      }
    }
  });

  _exports.default = _default;
});