define("ember-bootstrap-controls/components/freestyle/modals/ember-model", ["exports", "ember-bootstrap-controls/templates/components/freestyle/modals/ember-model"], function (_exports, _emberModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _emberModel.default,
    isBasicOpen: false,
    isAdvancedOpen: false,
    init: function init() {
      this.widget = {
        label: '',
        save: function save() {
          return true;
        },
        get: function get() {
          return false;
        }
      };
      this.slowWidget = {
        label: '',
        save: function save() {
          var delay = 3000;
          return new Ember.RSVP.Promise(function (resolve) {
            setTimeout(function () {
              resolve(true);
            }, delay);
          });
        },
        get: function get() {
          return false;
        }
      };

      this._super.apply(this, arguments);
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isBasicOpen');
      },
      toggleAdvancedModal: function toggleAdvancedModal() {
        this.toggleProperty('isAdvancedOpen');
      },
      beforeSave: function beforeSave() {
        return true;
      },
      afterSave: function afterSave() {
        window.alert("Save successful");
      },
      afterCancel: function afterCancel() {
        window.alert("Cancel successful");
      }
    }
  });

  _exports.default = _default;
});