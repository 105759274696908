define("ember-authenticate-me/configuration", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.configure = configure;
  exports.default = getConfig;
  var configuration = {};

  function configure(settings) {
    configuration = settings;
  }

  function getConfig() {
    return configuration;
  }
});