define('ember-authenticate-me/controllers/sign-up', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    transitionRoute: 'index',
    alwaysSkipPreviousTransition: false,

    isTransitioningToPrevious: computed('previousTransition', 'alwaysSkipPreviousTransition', function () {
      var previousTransition = get(this, 'previousTransition');
      var usePreviousTransition = !get(this, 'alwaysSkipPreviousTransition');

      return previousTransition && usePreviousTransition && previousTransition.targetName !== 'logout' && previousTransition.targetName !== 'login';
    }),

    firstError: computed('model.errors.firstObject', function () {
      var model = get(this, 'model');
      var errorMessage = get(model, 'errors.firstObject');

      if (errorMessage) {
        var readableAttribute = errorMessage.attribute.dasherize().replace('-', ' ');
        return readableAttribute.capitalize() + ": " + errorMessage.message;
      } else {
        return null;
      }
    }),

    transitionToPrevious: function transitionToPrevious() {
      var previousTransition = this.get('previousTransition');

      this.set('previousTransition', null);
      previousTransition.retry();
    },

    transitionToLoggedInRoute: function transitionToLoggedInRoute() {
      var transitionRoute = this.get('transitionRoute');

      this.transitionToRoute(transitionRoute);
    },


    loginNewUser: function loginNewUser(username, password) {
      var _this = this;

      var isTransitioningToPrevious = this.get('isTransitioningToPrevious');
      var authenticationParams = {
        username: username,
        password: password
      };

      this.get('session').open('traditional-authentication', authenticationParams).then(function () {
        if (isTransitioningToPrevious) {
          _this.transitionToPrevious();
        } else {
          _this.transitionToLoggedInRoute();
        }
      }).catch(function (error) {
        Ember.Logger.error('Error authenticating new user: ', error);

        throw error;
      });
    },

    signupUser: function signupUser(model) {
      var _this2 = this;

      var username = get(model, 'username');
      var password = get(model, 'password');

      model.get('errors').clear();
      return model.save().then(function () /* user */{
        set(model, 'password', null);
        set(model, 'passwordConfirmation', null);

        _this2.loginNewUser(username, password);
      });
    },


    actions: {
      signup: function signup(model) {
        return this.signupUser(model);
      }
    }
  });
});