define("ember-bootstrap-controls/components/modals/header/-close-button", ["exports", "ember-bootstrap-controls/templates/components/modals/header/-close-button", "ember-bootstrap-controls/components/bootstrap/buttons/-button"], function (_exports, _closeButton, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _button.default.extend({
    layout: _closeButton.default,
    classNames: ['close'],
    attributeBindings: ['type', 'data-dismiss', 'aria-label'],
    type: 'button',
    'data-dismiss': 'modal',
    'aria-label': 'close'
  });

  _exports.default = _default;
});