define('ember-freestyle/components/freestyle-palette-item', ['exports', 'ember-freestyle/templates/components/freestyle-palette-item'], function (exports, _freestylePaletteItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Ember.Component.extend({
    layout: _freestylePaletteItem.default,
    tagName: '',
    // color - passed in
    paletteStyle: computed('color', function () {
      var color = this.get('color');
      return htmlSafe('background-color: ' + color.base + ';');
    })
  });
});