define("ember-bootstrap-controls/routes/pagination", ["exports", "ember-bootstrap-controls/mixins/routes/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PaginationRoute = Ember.Route.extend(_pagination.default);
  var _default = PaginationRoute;
  _exports.default = _default;
});