define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/-text", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/-text", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-text"], function (_exports, _text, _propDefinitionTools, _text2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _text.default,
    propDefinitions: _text2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_text2.propDefinitions), {
      value: '',
      label: 'Simple Text'
    }),
    basicValue: ''
  });

  _exports.default = _default;
});