define('ember-authenticate-me/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var observer = Ember.observer;
  exports.default = Model.extend({
    username: attr('string'),
    email: attr('string'),

    createdAt: attr('date'),
    updatedAt: attr('date'),

    /* not persisted in the local store, only sent to server */
    password: null,
    passwordConfirmation: null,
    currentPassword: null,

    passwordUpdate: observer('password', function () {
      var errors = this.get('errors');

      if (errors) {
        errors.remove('password');
      }
    }),

    passwordConfirmationUpdate: observer('passwordConfirmation', function () {
      var errors = this.get('errors');

      if (errors) {
        errors.remove('passwordConfirmation');
      }
    }),

    currentPasswordUpdate: observer('currentPassword', function () {
      var errors = this.get('errors');

      if (errors) {
        errors.remove('currentPassword');
      }
    })
  });
});