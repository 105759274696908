define('ember-freestyle/components/freestyle-note', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FreestyleNote = Ember.Component.extend({
    classNames: ['FreestyleNote']
  });

  FreestyleNote.reopenClass({
    positionalParams: ['slug']
  });

  exports.default = FreestyleNote;
});