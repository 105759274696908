define("ember-bootstrap-controls/components/freestyle/bootstrap/tables/-table", ["exports", "ember-bootstrap-controls/components/templates/components/freestyle/bootstrap/tables/-table", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/-table"], function (_exports, _table, _propDefinitionTools, _table2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _table.default,
    propDefinitions: _table2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_table2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});