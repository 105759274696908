define('ember-freestyle/components/freestyle-typeface', ['exports', 'ember-freestyle/templates/components/freestyle-typeface'], function (exports, _freestyleTypeface) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Ember.Component.extend({
    layout: _freestyleTypeface.default,
    classNames: ['FreestyleTypeface'],
    attributeBindings: ['fontFamilyStyle:style'],

    fontFamilyStyle: computed('fontFamily', function () {
      return htmlSafe('font-family: ' + this.get('fontFamily') + ';');
    })
  });
});