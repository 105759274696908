define('ember-prop-types/utils/validators/shape', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (validators, ctx, name, value, def, logErrors, throwErrors) {
    var typeDefs = def.typeDefs;
    var msg = 'Expected property ' + name + ' to match given shape';
    var shape = void 0;
    try {
      shape = JSON.stringify(value, null, ' ');
      msg = msg + ', but instead got value ' + shape;
    } catch (e) {}

    if (typeOf(typeDefs) !== 'object') {
      _logger.default.warn(ctx, 'PropTypes.shape() requires a plain object to be be passed in as an argument', throwErrors);
      return false;
    }

    if (typeOf(value) !== 'object') {
      _logger.default.warn(ctx, msg, throwErrors);
      return false;
    }

    var valid = Object.keys(typeDefs).every(function (key) {
      var typeDef = typeDefs[key];

      var objectValue = get(value, key);
      if (objectValue === undefined) {
        if (!typeDef.required) {
          return true;
        } else {
          if (logErrors) {
            _logger.default.warn(ctx, 'Property ' + name + ' is missing required property ' + key, throwErrors);
          }
          return false;
        }
      }

      return validators[typeDef.type](ctx, name + '.' + key, objectValue, typeDef, logErrors, throwErrors);
    });

    valid = valid && Object.keys(value).every(function (key) {
      var keyIsKnown = key in typeDefs;
      if (!keyIsKnown && logErrors) {
        _logger.default.warn(ctx, 'Property ' + name + ' has an unknown key: ' + key, throwErrors);
      }
      return keyIsKnown;
    });

    if (!valid && logErrors) {
      _logger.default.warn(ctx, msg, throwErrors);
    }

    return valid;
  };

  var get = Ember.get,
      typeOf = Ember.typeOf;
});