define("ember-bootstrap-controls/components/progress-bars/-bar", ["exports", "ember-bootstrap-controls/templates/components/progress-bars/-bar"], function (_exports, _bar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bar.default,
    classNames: ['progress-bar'],
    attributeBindings: ['role', 'currentValue:aria-valuenow', 'minimumValue:aria-valuemin', 'maximumValue:aria-valuemax'],
    role: 'progressbar',
    minimumValue: 0,
    maximumValue: 100,
    currentValue: 100,
    updateStyles: function updateStyles() {
      var max = this.get('maximumValue');
      var min = this.get('minimumValue');
      var total = max - min;
      var current = this.get('currentValue');
      var completed = current - min;
      var percentCompleted = Math.floor(completed / total * 100);
      this.element.style.width = "".concat(percentCompleted, "%");
    },
    didInsertElement: function didInsertElement() {
      this.updateStyles();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.updateStyles();
    }
  });

  _exports.default = _default;
});