define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/markdown", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/markdown", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-search"], function (_exports, _markdown, _propDefinitionTools, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _markdown.default,
    propDefinitions: _search.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_search.propDefinitions), {
      value: ''
    }),
    basicValue: '',
    actions: {
      onChange: function onChange(value) {
        alert("Value changed to ".concat(value));
      }
    }
  });

  _exports.default = _default;
});