define("ember-bootstrap-controls/components/freestyle/bootstrap-checkbox", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-checkbox"], function (_exports, _bootstrapCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bootstrapCheckbox.default,
    classNames: ['freestyle-checkbox'],
    isChecked: true,
    isNotChecked: false,
    actions: {
      checkboxChange: function checkboxChange()
      /*newChecked, oldChecked*/
      {}
    }
  });

  _exports.default = _default;
});