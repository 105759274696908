define('ember-prop-types/utils/validators/ember-component', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (ctx, name, value, def, logErrors, throwErrors) {
    var isObject = typeOf(value) === 'object';

    var valid = isObject && Object.keys(value).some(function (key) {
      // NOTE: this is based on internal API and thus could break without warning.
      return key.indexOf('COMPONENT_CELL') > -1 || // Pre Glimmer 2
      key.indexOf('COMPONENT DEFINITION') === 0 || // Glimmer 2
      // Added for: Ember CLI 3.2.0 after upgrade from Ember CLI 2.12.3
      key.indexOf('CURRIED COMPONENT DEFINITION') === 0;
    });

    if (!valid && logErrors) {
      _logger.default.warn(ctx, 'Expected property ' + name + ' to be an Ember.Component but instead got: ' + typeOf(value), throwErrors);
    }

    return valid;
  };

  var typeOf = Ember.typeOf;
});