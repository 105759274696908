define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/-email", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/-email", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-email"], function (_exports, _email, _propDefinitionTools, _email2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _email.default,
    propDefinitions: _email2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_email2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});