define('ember-authenticate-me/services/session-lifecycle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    _loginCallbacks: [],
    _logoutCallbacks: [],

    userLoggedIn: function userLoggedIn(session) {
      var loginCallbacks = this.get('_loginCallbacks');

      Ember.run(function () {
        loginCallbacks.forEach(function (func) {
          return Ember.run.next(null, function () {
            return func(session);
          });
        });
      });
    },
    userLoggedOut: function userLoggedOut(session) {
      var logoutCallbacks = this.get('_logoutCallbacks');

      Ember.run(function () {
        logoutCallbacks.forEach(function (func) {
          return Ember.run.next(null, function () {
            return func(session);
          });
        });
      });
    },
    registerLoginCallback: function registerLoginCallback(func) {
      var loginCallbacks = this.get('_loginCallbacks');

      this.set('_loginCallbacks', loginCallbacks.concat([func]));
    },
    registerLogoutCallback: function registerLogoutCallback(func) {
      var logoutCallbacks = this.get('_logoutCallbacks');

      this.set('_logoutCallbacks', logoutCallbacks.concat([func]));
    }
  });
});