define('ember-freestyle/components/freestyle-snippet', ['exports', 'ember-freestyle/templates/components/freestyle-snippet'], function (exports, _freestyleSnippet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;


  var LANGUAGES = {
    js: 'javascript',
    hbs: 'handlebars',
    css: 'css',
    scss: 'scss',
    less: 'less',
    md: 'markdown'
  };

  exports.default = Ember.Component.extend({
    layout: _freestyleSnippet.default,
    unindent: true,
    emberFreestyle: inject.service(),

    _unindent: function _unindent(snippet) {
      if (!this.get('unindent')) {
        return snippet;
      }
      var match = void 0,
          min = void 0;
      var unindentedSnippet = snippet;
      var lines = unindentedSnippet.split('\n');
      for (var i = 0; i < lines.length; i++) {
        match = /^\s*/.exec(lines[i]);
        if (match && (typeof min === 'undefined' || min > match[0].length)) {
          min = match[0].length;
        }
      }
      if (typeof min !== 'undefined' && min > 0) {
        unindentedSnippet = unindentedSnippet.replace(new RegExp('(\\n|^)\\s{' + min + '}', 'g'), '$1');
      }
      return unindentedSnippet;
    },


    source: computed('name', function () {
      return this._unindent((this.get('emberFreestyle.snippets')[this.get('name')] || '').replace(/^(\s*\n)*/, '').replace(/\s*$/, ''));
    }),

    didInsertElement: function didInsertElement() {
      var pre = this.$('pre');
      if (pre[0] && this.get('source')) {
        this.get('emberFreestyle').highlight(pre[0]);
      }
    },


    language: computed('name', function () {
      if (this.get('name').indexOf('--notes') >= 0) {
        return 'markdown';
      }

      var match = /\.(\w+)$/i.exec(this.get('name'));
      if (match) {
        return LANGUAGES[match[1]];
      }
    })
  });
});