define("ember-bootstrap-controls/components/freestyle/bootstrap/simple-form", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap/simple-form", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap/simple-form"], function (_exports, _simpleForm, _propDefinitionTools, _simpleForm2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _simpleForm.default,
    propDefinitions: _simpleForm2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_simpleForm2.propDefinitions), {
      value: ''
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('basicString', ''), this.set('basicNumber', 1), this.set('basicBoolean', false), this.set('basicDate', new Date()), this.set('radioOptions', Array(10).fill().map(function (item, index) {
        return 1 + index;
      })), this.set('radioValue', 1);
    }
  });

  _exports.default = _default;
});