define('ember-bootstrap-controls/components/bootstrap-radio-button', ['exports', 'ember-bootstrap-controls/templates/components/bootstrap-radio-button'], function (exports, _bootstrapRadioButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bootstrapRadioButton.default,
    isChecked: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('isChecked', this.get('option') === this.get('selected'));
    }
  });
});