define("ember-bootstrap-controls/components/modals/form/-body", ["exports", "ember-bootstrap-controls/templates/components/modals/form/-body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _body.default,
    tagName: '',
    savingProgressBarText: 'Saving',
    cancelingProgressBarText: 'Canceling'
  });

  _exports.default = _default;
});