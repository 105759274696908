define("ember-bootstrap-controls/components/modals/-body", ["exports", "ember-bootstrap-controls/templates/components/modals/-body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _body.default,
    tagName: ''
  });

  _exports.default = _default;
});