define("ember-bootstrap-controls/components/freestyle/bootstrap/tables/-thead", ["exports", "ember-bootstrap-controls/components/templates/components/freestyle/bootstrap/tables/-thead", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/-thead"], function (_exports, _thead, _propDefinitionTools, _thead2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _thead.default,
    propDefinitions: _thead2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_thead2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});