define("ember-bootstrap-controls/components/modals/-header", ["exports", "ember-bootstrap-controls/templates/components/modals/-header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _header.default,
    tagName: '',
    closeButton: true
  });

  _exports.default = _default;
});