define('ember-freestyle/components/freestyle-variant', ['exports', 'ember-freestyle/templates/components/freestyle-variant'], function (exports, _freestyleVariant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    layout: _freestyleVariant.default,
    classNames: ['FreestyleVariant'],
    classNameBindings: ['inline:FreestyleVariant--inline'],

    inline: computed('collection.inline', 'collection.activeKey', function () {
      var activeKey = this.get('collection.activeKey');
      return this.get('collection.inline') && activeKey === 'all';
    }),

    show: computed('collection.activeKey', function () {
      var activeKey = this.get('collection.activeKey');
      return activeKey === 'all' || activeKey === this.get('key');
    }),

    willRender: function willRender() {
      this._super.apply(this, arguments);
      this.get('collection').registerVariant(this.get('key'));
    }
  });
});