define('ember-authenticate-me/controllers/reset-password', ['exports', 'ember-authenticate-me/configuration'], function (exports, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var run = Ember.run;
  exports.default = Ember.Controller.extend({
    password: null,
    passwordConfirmation: null,
    error: null,

    checkPassword: Ember.observer('password', 'passwordConfirmation', function () {
      var _this = this;

      var callback = this.get('checkPasswordCallback');

      run(function () {
        run.cancel(callback);

        callback = run.later(_this, function () {
          var password = this.get('password');
          var passwordConfirmation = this.get('passwordConfirmation');

          this.set('error', null);

          if (!password) {
            this.set('error', "Password cannot be blank.");
          }
          if (password && password !== passwordConfirmation) {
            this.set('error', "Passwords must match.");
          }
        }, 600);

        _this.set('checkPasswordCallback', callback);
      });
    }),

    passwordResetComplete: function passwordResetComplete() {
      this.transitionToRoute('/login');
    },


    actions: {
      reset: function reset() {
        var emberAuthenticateMeConfig = (0, _configuration.default)();
        var error = this.get('error');
        var self = this;
        var PASSWORD_RESET_URI = emberAuthenticateMeConfig.passwordResetUri || '/api/password_resets';

        if (!error) {
          return Ember.$.ajax({
            type: "PUT",
            url: [PASSWORD_RESET_URI, this.get('model.token')].join('/'),

            data: {
              password: this.get('password'),
              password_confirmation: this.get('passwordConfirmation')
            },

            success: function success() /*data*/{
              self.passwordResetComplete();
            },
            error: function error(err /*, text*/) {
              self.set('error', 'An unknown error has occurred.');
              throw err;
            }
          });
        }
      }
    }
  });
});