define("ember-bootstrap-controls/components/bootstrap/tables/-body", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/tables/-body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _body.default,
    tagName: 'tbody',
    computedColumnCount: Ember.computed('actionsEnabled', function () {
      if (this.get('actionsEnabled')) {
        return 1;
      } else {
        return 0;
      }
    }),
    totalColumnCount: Ember.computed('definedColumnCount', 'computedColumnCount', function () {
      return parseInt(this.get('definedColumnCount')) + parseInt(this.get('computedColumnCount'));
    }),
    arrayExists: Ember.computed('rowsData', function () {
      return Ember.isArray(this.get('rowsData'));
    }),
    loadedRowsData: Ember.computed.or('rowsData.isLoaded', 'rowsData.isFulfilled', 'arrayExists'),
    reloadedRowsData: Ember.computed.not('reloadingRowsData'),
    rowsDataLoaded: Ember.computed.and('loadedRowsData', 'reloadedRowsData')
  });

  _exports.default = _default;
});