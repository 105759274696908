define('ember-authenticate-me/routes/reset-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var token = _ref.token;

      return {
        token: token
      };
    }
  });
});