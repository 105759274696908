define("ember-bootstrap-controls/utils/extensions/jquery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkInView;

  function checkInView() {
    if (Ember.$.fn.checkInView) {
      return;
    }

    Ember.$.fn.checkInView = function () {
      var partial = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var elem = this;

      if (!elem.length) {
        return false;
      }

      var container = elem.parent();
      var contHeight = container.height();
      container.scrollTop();
      var elemTop = elem.offset().top - container.offset().top;
      var elemBottom = elemTop + elem.height();
      var isTotal = elemTop >= 0 && elemBottom <= contHeight;
      var isPart = (elemTop < 0 && elemBottom > 0 || elemTop > 0 && elemTop <= container.height()) && partial;
      return isTotal || isPart;
    };
  }
});