define("ember-bootstrap-controls/components/bootstrap/simple-select", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/simple-select", "ember-prop-types", "ember-bootstrap-controls/utils/prop-definition-tools"], function (_exports, _simpleSelect, _emberPropTypes, _propDefinitionTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.propDefinitions = void 0;
  var propDefinitions = {
    autofocus: {
      default: false,
      description: 'This Boolean attribute lets you specify that a form control should have input focus when the page loads. Only one form element in a document can have the autofocus attribute.',
      type: _emberPropTypes.PropTypes.bool
    },
    bootstrapCustomClass: {
      default: false,
      description: 'This Boolean attribute lets you specify custom-select class should be applied to the select.',
      type: _emberPropTypes.PropTypes.bool
    },
    disabled: {
      description: 'Indicates whether the control is disabled',
      type: _emberPropTypes.PropTypes.bool
    },
    errors: {
      description: 'An array of EmberData errors to display.',
      type: _emberPropTypes.PropTypes.arrayOf(_emberPropTypes.PropTypes.string)
    },
    help: {
      description: 'Additonal text to provide additional context to the user that is displayed below the input.',
      type: _emberPropTypes.PropTypes.string
    },
    multiple: {
      default: false,
      description: 'This Boolean attribute indicates that multiple options can be selected in the list. If it is not specified, then only one option can be selected at a time. When multiple is specified, most browsers will show a scrolling list box instead of a single line dropdown.',
      type: _emberPropTypes.PropTypes.bool
    },
    required: {
      default: false,
      description: 'Indicates that the user must fill in a value before submitting a form.',
      type: _emberPropTypes.PropTypes.bool
    },
    srOnly: {
      default: false,
      description: 'Indicated that the label should be hidden to all devices except screen readers',
      type: _emberPropTypes.PropTypes.bool
    },
    value: {
      description: 'A string that is the value for the control.',
      type: _emberPropTypes.PropTypes.string.isRequired
    },
    label: {
      default: 'Text',
      description: 'The label shown above the input box.',
      type: _emberPropTypes.PropTypes.string
    },
    options: {
      description: 'A collection of option values for each radio button.',
      type: _emberPropTypes.PropTypes.arrayOf(_emberPropTypes.PropTypes.object).isRequired
    },
    onChange: {
      description: 'A function that is called when the radio is changed.',
      type: _emberPropTypes.PropTypes.func.isRequired
    }
  };
  _exports.propDefinitions = propDefinitions;

  var _default = Ember.Component.extend({
    layout: _simpleSelect.default,
    propTypes: (0, _propDefinitionTools.BuilderForPropTypes)(propDefinitions),
    getDefaultProps: function getDefaultProps() {
      return (0, _propDefinitionTools.BuilderForPropDefaults)(propDefinitions);
    }
  });

  _exports.default = _default;
});