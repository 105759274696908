define('ember-ordinal-indicators/utils/ordinal-indicator', ['exports'], function (exports) {
  var floor = Math.floor;

  exports['default'] = function (number) {
    if (floor(number / 10) === 1) {
      return 'th';
    } else if (number % 10 === 1) {
      return 'st';
    } else if (number % 10 === 2) {
      return 'nd';
    } else if (number % 10 === 3) {
      return 'rd';
    } else {
      return 'th';
    }
  };
});