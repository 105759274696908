define("ember-bootstrap-controls/components/freestyle/bootstrap-inputs/-number", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-inputs/-number", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/bootstrap-inputs/-number"], function (_exports, _number, _propDefinitionTools, _number2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _number.default,
    propDefinitions: _number2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_number2.propDefinitions), {
      value: '',
      label: 'Number Input'
    }),
    basicValue: ''
  });

  _exports.default = _default;
});