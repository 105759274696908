define('ember-bootstrap-controls/components/bootstrap-checkbox-button', ['exports', 'ember-bootstrap-controls/templates/components/bootstrap-checkbox-button'], function (exports, _bootstrapCheckboxButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bootstrapCheckboxButton.default,
    isChecked: false,
    isNotChecked: Ember.computed('isChecked', function () {
      return !this.get('isChecked');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var selected = Ember.A(this.get('selected'));
      this.set('isChecked', selected.includes(this.get('option')));
    }
  });
});