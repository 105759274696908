define("ember-bootstrap-controls/components/freestyle/bootstrap/tables/body/row/actions/-archive", ["exports", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/templates/components/freestyle/bootstrap/tables/body/row/actions/-archive", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/body/bootstrap/tables/body/row/actions/-archive"], function (_exports, _archive, _propDefinitionTools, _archive2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _archive.default,
    propDefinitions: _archive2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_archive2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});