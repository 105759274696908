define("ember-bootstrap-controls/components/bootstrap/radio-group", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/radio-group", "ember-prop-types", "ember-bootstrap-controls/utils/prop-definition-tools"], function (_exports, _radioGroup, _emberPropTypes, _propDefinitionTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.propDefinitions = void 0;
  var propDefinitions = {
    disabled: {
      description: 'Indicates whether the control is disabled',
      type: _emberPropTypes.PropTypes.bool
    },
    errors: {
      description: 'An array of EmberData errors to display.',
      type: _emberPropTypes.PropTypes.arrayOf(_emberPropTypes.PropTypes.string)
    },
    help: {
      description: 'Additonal text to provide additional context to the user that is displayed below the input.',
      type: _emberPropTypes.PropTypes.string
    },
    inline: {
      default: false,
      description: 'Adds `form-check-inline` to all radio inputs to create an inline group.',
      type: _emberPropTypes.PropTypes.bool
    },
    required: {
      default: false,
      description: 'Indicates that the user must fill in a value before submitting a form.',
      type: _emberPropTypes.PropTypes.bool
    },
    srOnly: {
      default: false,
      description: 'Indicated that the label should be hidden to all devices except screen readers',
      type: _emberPropTypes.PropTypes.bool
    },
    value: {
      description: 'A string that is the value for the control.',
      type: _emberPropTypes.PropTypes.string.isRequired
    },
    label: {
      default: 'Text',
      description: 'The label shown above the input box.',
      type: _emberPropTypes.PropTypes.string
    },
    options: {
      description: 'A collection of option values for each radio button.',
      type: _emberPropTypes.PropTypes.arrayOf(_emberPropTypes.PropTypes.object).isRequired
    },
    onChange: {
      description: 'A function that is called when the radio is changed.',
      type: _emberPropTypes.PropTypes.func.isRequired
    }
  };
  _exports.propDefinitions = propDefinitions;

  var _default = Ember.Component.extend({
    tagName: 'fieldset',
    attributeBindings: ['disabled', 'role', 'labelId:aria-labelledby'],
    role: 'radiogroup',
    layout: _radioGroup.default,
    propTypes: (0, _propDefinitionTools.BuilderForPropTypes)(propDefinitions),
    getDefaultProps: function getDefaultProps() {
      return (0, _propDefinitionTools.BuilderForPropDefaults)(propDefinitions);
    },
    lastIndex: Ember.computed('options', function () {
      return this.get('options.length') - 1;
    }),
    inputId: Ember.computed(function () {
      return "bootstrap-control-input-".concat(Ember.guidFor(this));
    }),
    helpId: Ember.computed(function () {
      return "".concat(Ember.guidFor(this), "-help");
    }),
    labelId: Ember.computed(function () {
      return "".concat(Ember.guidFor(this), "-group-label");
    })
  });

  _exports.default = _default;
});