define('ember-authenticate-me/routes/edit-account', ['exports', 'ember-authenticate-me/routes/authenticated'], function (exports, _authenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticated.default.extend({
    model: function model(params) {
      return this.get('store').find('token-authenticate-me/user', params.user_id);
    }
  });
});