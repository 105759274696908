define("ember-bootstrap-controls/components/freestyle-prop-types/-instance-of", ["exports", "ember-bootstrap-controls/templates/components/freestyle-prop-types/-instance-of"], function (_exports, _instanceOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _instanceOf.default,
    instanceType: '',
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('propDefinition.type.typeDef')) {
        this.set('instanceType', this.get('propDefinition.type.typeDef').toString());
      }
    }
  });

  _exports.default = _default;
});