define("ember-bootstrap-controls/mixins/components/inputable", ["exports", "ember-bootstrap-controls/utils/generate-uuid"], function (_exports, _generateUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    errors: undefined,
    hasSuccess: undefined,
    hasWarning: undefined,
    disabled: undefined,
    hasError: Ember.computed.alias('errors.length'),
    hasValue: Ember.computed('value', function () {
      var value = this.get('value');
      return value ? true : false;
    }),
    showError: Ember.computed('hasError', 'required', 'hasValue', function () {
      var hasError = this.get('hasError');
      var required = this.get('required');
      var hasValue = this.get('hasValue');
      return hasError || required && !hasValue;
    }),
    inputId: Ember.computed(function () {
      return "bootstrap-component-".concat((0, _generateUuid.default)());
    })
  });

  _exports.default = _default;
});