define('ember-prop-types/utils/validators/symbol', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (ctx, name, value, def, logErrors, throwErrors) {
    var valid = (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'symbol';

    if (!valid && logErrors) {
      _logger.default.warn(ctx, 'Expected property ' + name + ' to be a symbol but instead got: ' + typeOf(value), throwErrors);
    }

    return valid;
  };

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var typeOf = Ember.typeOf;
});