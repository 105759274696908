define("ember-bootstrap-controls/components/freestyle/bootstrap/tables/-action-bar", ["exports", "ember-bootstrap-controls/components/templates/components/freestyle/bootstrap/tables/-action-bar", "ember-bootstrap-controls/utils/prop-definition-tools", "ember-bootstrap-controls/components/freestyle/bootstrap/tables/-action-bar"], function (_exports, _actionBar, _propDefinitionTools, _actionBar2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _actionBar.default,
    propDefinitions: _actionBar2.propDefinitions,
    data: Object.assign((0, _propDefinitionTools.BuilderForPropDefaults)(_actionBar2.propDefinitions), {
      value: ''
    }),
    basicValue: ''
  });

  _exports.default = _default;
});