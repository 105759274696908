define("ember-bootstrap-controls/components/bootstrap-inputs/-select", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-inputs/-select"], function (_exports, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _select.default,
    lastIndex: Ember.computed('options', function () {
      return this.get('options.length') - 1;
    })
  });

  _exports.default = _default;
});