define('ember-prop-types/extensions/component-prop-types', ['ember-prop-types/mixins/prop-types', 'ember-prop-types/utils/prop-types'], function (_propTypes, _propTypes2) {
  'use strict';

  var Component = Ember.Component,
      isArray = Ember.isArray;


  /**
   * @module
   */

  /**
   * @memberof ember/Component#
   */
  Component.reopen(_propTypes.default, {
    init: function init() {
      if (_propTypes.settings.requireComponentPropTypes) {
        var propTypes = this.get('propTypes');

        if (!isArray(propTypes) || propTypes.length === 0) {
          _propTypes2.logger.warn(this, 'propTypes is required for components', _propTypes.settings.throwErrors);
        }
      }

      this._super.apply(this, arguments);
    }
  });
});