define('ember-authenticate-me/routes/sign-up', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var user = this.get('store').createRecord('token-authenticate-me/user');

      return user;
    }
  });
});