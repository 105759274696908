define('ember-authenticate-me/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    networks: [],
    username: null,
    password: null,
    transitionRoute: 'index',
    alwaysSkipPreviousTransition: false,
    sessionLifecycle: Ember.inject.service('session-lifecycle'),

    transitionToPrevious: function transitionToPrevious() {
      var previousTransition = this.get('previousTransition');

      this.set('previousTransition', null);
      previousTransition.retry();
    },

    transitionToLoggedInRoute: function transitionToLoggedInRoute() {
      var transitionRoute = this.get('transitionRoute');

      this.transitionToRoute(transitionRoute);
    },

    usePreviousTransition: computed('previousTransition', 'alwaysSkipPreviousTransition', function () {
      var previousTransition = get(this, 'previousTransition');
      var usePreviousTransition = !get(this, 'alwaysSkipPreviousTransition');

      return previousTransition && usePreviousTransition && previousTransition.targetName !== 'logout' && previousTransition.targetName !== 'login';
    }),

    actions: {
      login: function login(username, password) {
        var _this = this;

        var usePreviousTransition = this.get('usePreviousTransition');

        var authenticationParams = {
          username: username || '',
          password: password || ''
        };

        return get(this, 'session').open('traditional-authentication', authenticationParams).then(function (sessionContent) {
          var sessionLifecycle = _this.get('sessionLifecycle');
          sessionLifecycle.userLoggedIn(sessionContent);
          if (usePreviousTransition) {
            _this.transitionToPrevious(sessionContent);
          } else {
            _this.transitionToLoggedInRoute(sessionContent);
          }
        }).catch(function (error) {
          if (error && error.status === 0 && error.statusText === 'error') {
            set(_this, 'error', 'Unable to authenticate user: Cannot communicate with server');
          } else if (error && error.responseText) {
            set(_this, 'error', 'Unable to authenticate user: ' + JSON.parse(error.responseText).message);
          } else {
            Ember.Logger.error('Authentication Error: ', error);
            set(_this, 'error', 'Unable to authenticate user: An unknown error has occurred');
          }

          throw error; /* bubble error for others to view */
        });
      }
    }
  });
});