define("ember-bootstrap-controls/components/freestyle-prop-types/-bool", ["exports", "ember-bootstrap-controls/templates/components/freestyle-prop-types/-bool"], function (_exports, _bool) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bool.default,
    dataValue: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('dataValue', this.get("data.".concat(this.get('propDefinition.name'))));
    },
    actions: {
      booleanUpdated: function booleanUpdated(e) {
        var value = e.target.checked;
        this.set("data.".concat(this.get('propDefinition.name')), value);
      }
    }
  });

  _exports.default = _default;
});