define("ember-bootstrap-controls/components/bootstrap/tables/body/row/actions/-show", ["exports", "ember-bootstrap-controls/templates/components/bootstrap/tables/body/row/actions/-show"], function (_exports, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _show.default,
    tagName: ''
  });

  _exports.default = _default;
});