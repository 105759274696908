define("ember-bootstrap-controls/components/bootstrap-pagination-nav/page-item", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-pagination-nav/page-item"], function (_exports, _pageItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _pageItem.default,
    tagName: 'li',
    classNames: ['page-item'],
    attributeBindings: ['aria-label'],
    classNameBindings: ['disabled', 'active'],
    //TODO: Add prop definitions
    disabled: undefined,
    active: undefined
  });

  _exports.default = _default;
});