define("ember-bootstrap-controls/components/bootstrap-inputs/markdown", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-inputs/markdown", "ember-concurrency", "ember-bootstrap-controls/utils/prop-definition-tools"], function (_exports, _markdown, _emberConcurrency, _propDefinitionTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.propDefinitions = void 0;
  var propDefinitions = {
    /*
      autocomplete: {
        default: 'email',
        description: 'Indicates whether the value can be automatically completed by the browser.',
        type: PropTypes.oneOf(['off', 'email']),
      },
    */
  };
  _exports.propDefinitions = propDefinitions;

  var _default = Ember.Component.extend({
    layout: _markdown.default,
    propTypes: (0, _propDefinitionTools.BuilderForPropTypes)(propDefinitions),
    debounce: 800,
    getDefaultProps: function getDefaultProps() {
      return (0, _propDefinitionTools.BuilderForPropDefaults)(propDefinitions);
    }
  });

  _exports.default = _default;
});