define("ember-bootstrap-controls/components/bootstrap-power-select", ["exports", "ember-bootstrap-controls/templates/components/bootstrap-power-select"], function (_exports, _bootstrapPowerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.POWER_SELECT_CLASS_NAME = void 0;
  var POWER_SELECT_CLASS_NAME = 'bootstrap-power-select-component';
  _exports.POWER_SELECT_CLASS_NAME = POWER_SELECT_CLASS_NAME;

  var _default = Ember.Component.extend({
    layout: _bootstrapPowerSelect.default,
    classNames: [POWER_SELECT_CLASS_NAME],

    /***
     * IMPORTANT:
     *   Optional power-select overrides should be set as `undefined` if we want
     *   to allow the property to be overridden but do not want to provide a
     *   default value and instead just use the ember-power-select default. Do
     *   not use `null`.
     ***/
    // Power select attributes
    selected: undefined,
    placeholder: undefined,
    options: undefined,
    onChange: undefined,
    matcher: undefined,
    loadingMessage: undefined,
    noMatchesMessage: undefined,
    disabled: undefined,
    renderInPlace: true,
    allowClear: true,
    search: undefined,
    searchEnabled: true,
    searchField: undefined,
    searchMessage: undefined,
    required: undefined,
    // Power select component attributes
    afterOptionsComponent: undefined,
    beforeOptionsComponent: undefined,
    optionsComponent: undefined,
    groupComponent: undefined,
    triggerComponent: undefined,
    searchMessageComponent: undefined,
    placeholderComponent: undefined,
    // Bootstrap attributes
    srOnly: undefined,
    label: undefined,
    errors: undefined
  });

  _exports.default = _default;
});