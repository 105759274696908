define('ember-freestyle/components/freestyle-collection', ['exports', 'ember-freestyle/templates/components/freestyle-collection'], function (exports, _freestyleCollection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({
    layout: _freestyleCollection.default,
    classNames: ['FreestyleCollection'],
    classNameBindings: ['inline:FreestyleCollection--inline'],

    emberFreestyle: inject.service(),
    showLabels: computed.and('emberFreestyle.notFocused', 'emberFreestyle.showLabels'),
    hasLabels: computed.and('showLabels', 'title'),
    showVariantList: computed.not('emberFreestyle.focus'),

    defaultKey: 'all',
    activeKey: computed('defaultKey', 'key', 'emberFreestyle.focus', function () {
      if (this.get('emberFreestyle.focus')) {
        return 'all';
      }
      return this.get('key') || this.get('defaultKey');
    }),

    registerVariant: function registerVariant(variantKey) {
      var _this = this;

      Ember.run.next(function () {
        if (_this.isDestroyed) {
          return;
        }
        var variants = _this.get('variants') || Ember.A(['all']);
        if (!variants.includes(variantKey)) {
          variants.pushObject(variantKey);
        }
        _this.set('variants', variants);
      });
    },


    actions: {
      setKey: function setKey(key) {
        this.set('key', key);
      }
    }
  });
});