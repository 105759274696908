define("ember-bootstrap-controls/components/modals/ember-model/-footer", ["exports", "ember-bootstrap-controls/templates/components/modals/ember-model/-footer"], function (_exports, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _footer.default,
    isNewModel: false,
    acceptButtonText: Ember.computed('isNewModel', function () {
      return this.get('isNewModel') ? 'Save' : 'Update';
    })
  });

  _exports.default = _default;
});