define("ember-bootstrap-controls/components/freestyle/bootstrap-date-picker", ["exports", "ember-bootstrap-controls/templates/components/freestyle/bootstrap-date-picker"], function (_exports, _bootstrapDatePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bootstrapDatePicker.default,
    date: undefined
  });

  _exports.default = _default;
});